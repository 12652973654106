// Globals
import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import _ from 'lodash';
import Select from 'react-select';

// Project imports
import {dateToShortDate} from 'ki-common/utils/dateHelpers';

// Website imports
import KiDatePicker from 'components/KiDatePicker';
import KiSelect from 'components/KiSelect';
import KiButton from 'components/KiButton';

// Website api

// Local imports
import {BookmarkContext} from '../../fundingAnalysisBookmark';
import styles from './fundingExploration.theme.scss';

const explorationConstraintTypes = [{value: 'setup', label: 'FV Defined'}, {value: 'applied', label: 'Applied'}];

function QuickFilters({onApply}) {
	// Browser State
	const {datasetId} = useParams();

	// Redux State
	const allFundingVehicles = useSelector(state => state.fundingVehicleList.data);

	// Context State
	const bookmarkContext = useContext(BookmarkContext);
	const bookmark = bookmarkContext.bookmark;
	const bookmarkUi = bookmarkContext.ui;

	// Local State
	const [fundingViewTypes, setFundingViewTypes] = useState([
		{label: 'Summary', value: 'summary'},
		{label: 'Eligibility', value: 'eligibility'},
	]);

	useEffect(
		() => {
			setFundingViewTypes([
				{label: 'Summary', value: 'summary'},
				{label: 'Eligibility', value: 'eligibility'},
				{label: 'Scenario', value: 'scenario', isDisabled: _.isEmpty(bookmarkUi.scenarioList)},
			]);
		},
		[bookmarkUi.scenarioList]
	);

	// *************************
	// Render Helpers & Handlers
	// *************************

	const handleSetViewType = async val => {
		bookmarkContext.setViewType(val.value);
	};

	const handleSetStatementDate = async val => {
		bookmarkContext.setBookmark({
			settings: {
				statementDate: dateToShortDate(val),
			},
		});
	};

	const renderStatementDate = () => {
		return (
			<div className={styles.selectWrapper}>
				<span className="theme-label">Statement Date</span>
				<KiDatePicker onChange={handleSetStatementDate} value={bookmark.settings.statementDate} />
			</div>
		);
	};

	const handleSetDateContext = async val => {
		bookmarkContext.setBookmark({
			settings: {
				dateContext: val._id,
			},
		});
	};

	const renderDateContext = () => {
		const isLoading = _.isEmpty(bookmarkUi.datasetDateList);
		return (
			<div className={styles.selectWrapper}>
				<span className={'theme-label'}>Date Context</span>
				<KiSelect
					getOptionLabel={o => o.name}
					isLoading={isLoading}
					loadingMessage={() => 'Loading dates...'}
					placeholder={isLoading ? 'Loading Dates...' : 'Select a date'}
					getOptionValue={date => date._id}
					defaultValue={bookmarkUi.datasetDateList.find(d => d.name === 'Latest Snapshot')}
					name={'dateContext'}
					options={bookmarkUi.datasetDateList.filter(d => d.readOnly)}
					value={bookmarkUi.datasetDateList.find(
						dateColumn => dateColumn._id === bookmark.settings.dateContext
					)}
					onChange={handleSetDateContext}
				/>
			</div>
		);
	};

	const handleSetSummaryScenario = async val => {
		bookmarkContext.setBookmark({
			settings: {
				scenarioType: val.type === 'transferDate' ? 'lastCommitted' : val._id,
				transferDate: val.type === 'transferDate' ? val._id : null,
			},
		});
	};

	const renderSummaryScenarioSelector = () => {
		const scenarioFilterList = bookmarkUi.scenarioFilterList;
		const isLoading = _.isEmpty(scenarioFilterList);

		let value;
		const pendingOptions = scenarioFilterList.find(sc => sc.label === 'Pending');
		if (!_.isEmpty(bookmark.settings.transferDate) && !_.isEmpty(pendingOptions)) {
			value = pendingOptions.options.find(opt => opt._id === bookmark.settings.transferDate);
		} else {
			value = scenarioFilterList.find(sc => sc._id === bookmark.settings.scenarioType);
		}
		// TODO may need to test default logic here

		return (
			<div className={styles.selectWrapper}>
				<span className="theme-label">Scenario</span>
				<Select
					isOptionDisabled={option => !!option.disabled}
					loadingMessage={() => 'Loading...'}
					isLoading={isLoading}
					isDisabled={isLoading}
					classNamePrefix="aut-select"
					value={value}
					options={scenarioFilterList}
					//defaultValue={{name: 'End of Day', _id: 'lastApproved'}}
					onChange={handleSetSummaryScenario}
					getOptionLabel={option => option.name}
					getOptionValue={option => option._id}
				/>
			</div>
		);
	};

	const handleActualScenarioSelector = val => {
		bookmarkContext.setScenarioId(val._id);
	};

	const renderActualScenarioSelector = () => {
		return (
			<div className={styles.selectWrapper}>
				<span className="theme-label">Scenario</span>
				<Select
					classNamePrefix="aut-select"
					value={bookmarkUi.scenarioList.find(sc => sc._id === bookmark.settings.scenarioId)}
					options={bookmarkUi.scenarioList}
					onChange={handleActualScenarioSelector}
					getOptionLabel={option => option.name}
					getOptionValue={option => option._id}
				/>
			</div>
		);
	};

	const handleSetEligibilityCriteria = async val => {
		let valToSet;
		if ((val && val[val.length - 1].fvId === 'all') || val === null) {
			valToSet = ['all'];
		} else {
			valToSet = val.filter(v => v.fvId !== 'all');
			valToSet = valToSet.map(v => v.fvId);
		}
		bookmarkContext.setBookmark({
			settings: {
				criteriaSources: valToSet,
			},
		});
	};

	const getFvValues = (type = 'fundingVehicleIds') => {
		let fvs = _.get(bookmark, `settings.${type}`, []);
		if (fvs && fvs[0] && !fvs[0].fvId) {
			fvs = fvs.map(fv => {
				const name = ['all', 'global'].includes(fv.toLowerCase())
					? _.capitalize(fv)
					: _.get(allFundingVehicles.find(f => f._id === fv), 'name', '');
				return {
					fvId: fv,
					fvName: name,
				};
			});
		}
		return fvs;
	};

	const getEligibilityOptions = () => {
		const eligiblityCriteriaOptions = [
			{
				fvName: 'All',
				fvId: 'all',
			},
			{
				fvName: 'Global',
				fvId: 'global',
			},
		];
		allFundingVehicles.forEach(fv => {
			if (fv.datasetId === datasetId) {
				const option = {
					fvName: fv.name,
					fvId: fv._id,
				};
				if (!fv.isUnencumbered) {
					eligiblityCriteriaOptions.push(option);
				}
			}
		});

		return eligiblityCriteriaOptions;
	};

	const renderEligibilityCriteria = () => {
		return (
			<div className={styles.selectWrapper}>
				<span className="theme-label">Eligibility Criteria</span>
				<Select
					classNamePrefix="aut-select"
					value={getFvValues('criteriaSources')}
					options={getEligibilityOptions()}
					isMulti={true}
					onChange={handleSetEligibilityCriteria}
					getOptionLabel={option => option.fvName}
					getOptionValue={option => option.fvId}
				/>
			</div>
		);
	};

	const handleSetConstraints = async val => {
		bookmarkContext.setBookmark({
			settings: {
				constraintGroup: val.value,
			},
		});
	};

	const renderConstraints = () => {
		return (
			<div className={styles.selectWrapper}>
				<span className="theme-label">Constraints</span>
				<Select
					classNamePrefix="aut-select"
					value={explorationConstraintTypes.find(t => t.value === bookmark.settings.constraintGroup)}
					options={explorationConstraintTypes}
					onChange={handleSetConstraints}
				/>
			</div>
		);
	};

	const handleSetFundingVehicles = async val => {
		let valToSet;
		if (val === null || val.includes({fvId: 'all', fvName: 'All'})) {
			valToSet = ['all'];
		} else {
			valToSet = val.filter(v => v.fvId !== 'all');
			valToSet = valToSet.map(v => v.fvId);
		}
		bookmarkContext.setBookmark({
			settings: {
				fundingVehicleIds: valToSet,
			},
		});
	};

	// Summary FV list should be everything in the dataset
	const getFullFvList = () => {
		const assetSourceOptions = [];
		allFundingVehicles.forEach(fv => {
			if (fv.datasetId === datasetId) {
				const option = {
					fvName: fv.name,
					fvId: fv._id,
				};
				assetSourceOptions.push(option);
			}
		});

		return assetSourceOptions;
	};

	// Scenario FV list should only be FV's from the model that scenario is using
	const getScenarioFvList = () => {
		return bookmarkContext.scenario?.fvSettings
			? bookmarkContext.scenario.fvSettings.map(fv => ({
					fvName: fv.fvName,
					fvId: fv.fvId,
			  }))
			: [];
	};

	// Eligibility FV list should be everything in the dataset (should be called Source Funding Vehicles)
	const renderFundingVehicleMultiselect = (useFullFvList = true, isEligibility = false) => {
		const fvOptions = useFullFvList ? getFullFvList() : getScenarioFvList();
		// Add option for all
		fvOptions.unshift({
			fvName: 'All',
			fvId: 'all',
		});
		return (
			<div className={styles.selectWrapper}>
				<span className="theme-label">{isEligibility ? 'Source ' : ''}Funding Vehicles</span>
				<Select
					classNamePrefix="aut-select"
					value={getFvValues()}
					isClearable={false}
					options={fvOptions}
					isMulti={true}
					onChange={handleSetFundingVehicles}
					getOptionLabel={option => option.fvName}
					getOptionValue={option => option.fvId}
				/>
			</div>
		);
	};

	const renderFundingVehicleSingleSelect = (useFullFvList = true) => {
		const fvOptions = useFullFvList ? getFullFvList() : getScenarioFvList();
		// Find the first entry in the list that is not all to use
		const values = getFvValues().filter(fv => fv.Id != 'all');
		return (
			<div className={styles.selectWrapper}>
				<span className="theme-label">Funding Vehicles</span>
				<Select
					classNamePrefix="aut-select"
					value={_.get(values, '0', fvOptions[0])}
					isClearable={false}
					options={fvOptions}
					isMulti={false}
					// Wrap in array because that is how they are stored
					onChange={val => handleSetFundingVehicles([val])}
					getOptionLabel={option => option.fvName}
					getOptionValue={option => option.fvId}
				/>
			</div>
		);
	};

	// Scenario FV list should only be FV's from the model that scenario is using
	// Summary FV list should be everything in the dataset
	const renderFundingVehcleSelect = () => {
		const isMultiSelect = ['scenario', 'summary', 'eligibility'].includes(bookmark.settings.viewType);
		const useFullFvList = bookmark.settings.viewType !== 'scenario';
		const isEligibility = bookmark.settings.viewType === 'eligibility';
		if (isMultiSelect) {
			return renderFundingVehicleMultiselect(useFullFvList, isEligibility);
		}
		return renderFundingVehicleSingleSelect(useFullFvList);
	};

	// It should be noted that the way FV selection is stored on these bookmarks is in array form
	// so this component needs to translate between array to single when viewing and save the value
	// in an array form on change

	// Summary
	// LEFT - View Type, Statement Date
	// RIGHT - Date Context, Scenario, Funding Vehicles Multi

	// Summary Breaches/Excess
	// ['pool', 'breaches', 'excess'].includes(bookmark.settings.viewType)
	// LEFT - View Type, Statement Date
	// RIGHT - Date Context, Scenario, Funding Vehicle Single

	// Summary Pool via FV Click
	// ['pool', 'breaches', 'excess'].includes(bookmark.settings.viewType)
	// LEFT - View Type, Scenario, Constraints, Funding Vehicles Single

	// Eligibility
	// LEFT - View Type, Statement Date
	// RIGHT - Date Context, Eligibility Criteria, Funding Vehicles Multi

	// Scenario
	// LEFT - View Type, Scenario, Constraints, Funding Vehicles Multi

	// Scenario Breaches/Excess
	// ['pool', 'breaches', 'excess'].includes(bookmark.settings.viewType)
	// LEFT - View Type, Scenario, Constraints, Funding Vehicles Single

	// Scenario Pool via FV Click
	// ['pool', 'breaches', 'excess'].includes(bookmark.settings.viewType)
	// LEFT - View Type, Scenario, Constraints, Funding Vehicles Single

	return (
		<div>
			<section className={styles.quickFilters}>
				<div className={styles.selectWrapper}>
					<span className="theme-label">View Type</span>
					<Select
						classNamePrefix="aut-select"
						value={fundingViewTypes.find(vt => bookmark.settings.tableType == vt.value)}
						isClearable={false}
						options={fundingViewTypes}
						onChange={handleSetViewType}
					/>
				</div>

				{['eligibility', 'summary'].includes(bookmark.settings.tableType) && renderStatementDate()}

				{bookmark.settings.tableType === 'summary' && (
					<React.Fragment>
						{renderDateContext()}
						{renderSummaryScenarioSelector()}
					</React.Fragment>
				)}
				{['scenario', 'pool', 'excess', 'breaches'].includes(bookmark.settings.viewType) &&
					bookmark.settings.tableType !== 'summary' && (
						<React.Fragment>
							{renderActualScenarioSelector()}
							{renderConstraints()}
						</React.Fragment>
					)}
				{bookmark.settings.viewType === 'eligibility' && (
					<React.Fragment>
						{renderDateContext()}
						{renderEligibilityCriteria()}
					</React.Fragment>
				)}
				{renderFundingVehcleSelect()}
				<div className={styles.buttonWrapper}>
					<KiButton primary label="Apply" disabled={!bookmarkContext.hasChanges} onMouseUp={onApply} />
				</div>
			</section>
			<span>
				{['summary', 'eligibility'].includes(bookmark.settings.tableType) && (
					<div style={{fontSize: '1.4rem', marginBottom: '2rem'}}>{bookmarkUi.infoRibbonMessage}</div>
				)}
			</span>
		</div>
	);
}

QuickFilters.propTypes = {
	onApply: PropTypes.func.isRequired,
};

export default QuickFilters;
