import * as actionTypes from './actionTypes';
import {splitFundingVehicleSettingsByType} from 'ki-common/utils/fundingVehicleUtil';

const initialState = {
	isLoading: false,
	error: null,
	selected: null,
	selectedPools: [],
	fvModalActive: false,
	selectedSettings: {
		reportDates: [],
		manualInputs: [],
		creditSupports: [],
	},
	structures: {
		blockOverlayActive: false,
		allBlocks: [],
		currentBlock: null,
		allTracks: [],
		currentTrack: {
			name: '',
			blocks: [],
		},
	},
};

const getSettingType = rowType => {
	if (rowType === 'calculation') return 'calculation';
	if (rowType === 'legal') return 'legal';
	if (rowType === 'debt') return 'debt';
	if (rowType === 'fee') return 'fee';
	if (rowType === 'creditSupport') return 'creditSupport';
	if (rowType === 'counterparty') return 'counterparty';
	if (new RegExp('^cc', 'g').test(rowType)) return 'constraints';
};

const FundingVehicle = (state = initialState, action) => {
	//eslint-disable-line complexity
	switch (action.type) {
		case actionTypes.FUNDING_VEHICLES_FETCH_ONE:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						isLoading: true,
					};
				case 'success': {
					const selectedSettings = splitFundingVehicleSettingsByType(action.fundingVehicleSelected.settings);
					delete action.fundingVehicleSelected.settings;
					return {
						...state,
						selected: action.fundingVehicleSelected,
						selectedSettings: selectedSettings,
					};
				}
				case 'error':
					return {
						...state,
						error: action.msg,
						isLoading: false,
					};
				default:
					return state;
			}
		case actionTypes.FUNDING_VEHICLES_UPDATE_ONE:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						isLoading: true,
					};
				case 'success':
					return {
						...state,
						selected: action.fundingVehicleSelected,
						isLoading: false,
					};
				case 'error':
					return {
						...state,
						error: action.msg,
						isLoading: false,
					};
				default:
					return state;
			}
		case actionTypes.FUNDING_VEHICLES_FETCH_SETTINGS:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						isLoading: true,
					};
				case 'success':
					return {
						...state,
						selectedSettings: splitFundingVehicleSettingsByType(action.selectedSettings),
					};
				case 'error':
					return {
						...state,
						error: action.msg,
						isLoading: false,
					};
				default:
					return state;
			}
		case actionTypes.FUNDING_VEHICLES_FETCH_ONE_SETTING:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						isLoading: true,
					};
				case 'success': {
					const index = state.selectedSettings[getSettingType(action.setting.rowType)].findIndex(
						s => s._id === action.setting._id
					);
					if (index != -1) {
						const key = getSettingType(action.setting.rowType);
						return {
							...state,
							selectedSettings: {
								...state.selectedSettings,
								[key[index]]: action.setting,
							},
						};
					}
					return state;
				}
				case 'error':
					return {
						...state,
						error: action.msg,
						isLoading: false,
					};
				default:
					return state;
			}
		case actionTypes.FUNDING_VEHICLES_SETTING_UPDATE_ONE:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						isLoading: true,
					};
				case 'success':
					return {
						...state,
						isLoading: false,
					};
				case 'error':
					return {
						...state,
						error: action.msg,
						isLoading: false,
					};
				default:
					return state;
			}
		case actionTypes.FUNDING_VEHICLES_SETTING_ADD_ONE:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						isLoading: true,
					};
				case 'success':
					return {
						...state,
						isLoading: false,
					};
				case 'error':
					return {
						...state,
						error: action.msg,
						isLoading: false,
					};
				default:
					return state;
			}
		case actionTypes.FUNDING_VEHICLES_FETCH_POOLS:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						isLoading: true,
					};
				case 'success':
					return {
						...state,
						selectedPools: action.selectedPools,
						isLoading: false,
					};
				case 'error':
					return {
						...state,
						error: action.msg,
						isLoading: false,
					};

				default:
					return state;
			}
		case actionTypes.FUNDING_VEHICLES_SETTINGS_DELETE:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						isLoading: true,
					};
				case 'success':
				case 'error':
					return {
						...state,
						isLoading: false,
					};
				default:
					return state;
			}

		case actionTypes.FUNDING_VEHICLES_FETCH_ALL_REPORT_DATES:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						isLoading: true,
					};
				case 'success':
					return {
						...state,
						selectedSettings: {
							...state.selectedSettings,
							reportDates: action.reportDates,
						},
						isLoading: false,
					};
				case 'error':
					return {
						...state,
						isLoading: false,
					};
				default:
					return state;
			}

		case actionTypes.FUNDING_VEHICLES_FETCH_SETTINGS_BY_DATASETID:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						isLoading: true,
					};
				case 'success':
					return {
						...state,
						selectedSettings: splitFundingVehicleSettingsByType(action.selectedSettings),
						isLoading: false,
					};
				case 'error':
					return {
						...state,
						isLoading: false,
					};
				default:
					return state;
			}

		case actionTypes.FUNDING_VEHICLES_SAVE_BLOCK:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						isLoading: true,
					};
				case 'success':
					return {
						...state,
						isLoading: false,
					};
				case 'error':
					return {
						...state,
						isLoading: false,
					};
				default:
					return state;
			}

		case actionTypes.FUNDING_VEHICLES_DELETE_BLOCK:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						isLoading: true,
					};
				case 'success':
					return {
						...state,
						structures: {
							...state.structures,
							allBlocks: state.structures.allBlocks.filter(block => block._id !== action.blockId),
						},
						isLoading: false,
					};
				case 'error':
					return {
						...state,
						isLoading: false,
					};
				default:
					return state;
			}

		case actionTypes.FUNDING_VEHICLES_GET_BLOCK_LIST:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						isLoading: true,
					};
				case 'success':
					return {
						...state,
						structures: {
							...state.structures,
							allBlocks: action.blocks,
						},
					};
				case 'error':
					return {
						...state,
						isLoading: false,
					};
				default:
					return state;
			}

		case actionTypes.FUNDING_VEHICLES_BLOCK_MODAL_TOGGLE:
			return {
				...state,
				structures: {
					...state.structures,
					blockOverlayActive: action.isOpen,
				},
			};

		case actionTypes.FUNDING_VEHICLES_SET_CURRENT_BLOCK:
			return {
				...state,
				structures: {
					...state.structures,
					currentBlock: action.block,
					blockOverlayActive: true,
				},
			};

		case actionTypes.FUNDING_VEHICLES_GET_TRACK_LIST:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						isLoading: true,
					};
				case 'success':
					// const currTrack = state.structures.currentTrack._id ? action.tracks.find(t => t._id === state.structures.currentTrack._id) : state.structures.currentTrack;
					return {
						...state,
						structures: {
							...state.structures,
							allTracks: action.tracks,
							currentTrack: action.tracks[0] || {
								name: '',
								blocks: [],
							},
						},
					};
				case 'error':
					return {
						...state,
						isLoading: false,
					};
				default:
					return state;
			}

		case actionTypes.FUNDING_VEHICLES_SAVE_TRACK:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						isLoading: true,
					};
				case 'success':
					return {
						...state,
						isLoading: false,
					};
				case 'error':
					return {
						...state,
						isLoading: false,
					};
				default:
					return state;
			}

		case actionTypes.FUNDING_VEHICLES_SET_CURRENT_TRACK:
			return {
				...state,
				structures: {
					...state.structures,
					currentTrack: action.track,
				},
			};

		case actionTypes.FUNDING_VEHICLES_ADD_BLOCK_TO_TRACK: {
			const currentTrack = {...state.structures.currentTrack};
			currentTrack.blocks.push(action.block);
			return {
				...state,
				structures: {
					...state.structures,
					currentTrack: currentTrack,
				},
			};
		}

		default:
			return state;
	}
};

export default FundingVehicle;
