import PropTypes from 'prop-types';
import React from 'react';
import styles from '../reports.theme.scss';
import _get from 'lodash/get';
import KiFontIcon from 'components/KiFontIcon';
import {explorationViewTypes} from 'ki-common/options';

function CardSummary(props) {
	const {index, section, displayCard, setSelectedCard, statementDate, deleteSection, allowEdit, bookmark} = props;

	const getDateFromDateContext = displayCard => {
		if (!displayCard.dateContextData) return <div>{`Date Context: N/A`}</div>;
		const {card} = displayCard;
		const {dateContextAssetData, dateContextDebtData} = displayCard.dateContextData;
		switch (card.settings.type) {
			case 'tabular':
			case 'fundingAnalysisView':
				return (
					<div>
						{`Date Context: `}
						{displayCard.dateContextData.error ? (
							<span className="text-error-red">{`${displayCard.dateContextData.error}`}</span>
						) : (
							<span>
								{displayCard.dateContextData.name} {displayCard.dateContextData.calculatedDate}
							</span>
						)}
					</div>
				);
			case 'debtView':
				return (
					<div>
						<div>
							{`Asset Date Context: `}
							{dateContextAssetData.error ? (
								<span className="text-error-red">{`${dateContextAssetData.error}`}</span>
							) : (
								<span>
									{dateContextAssetData.name} {dateContextAssetData.calculatedDate}
								</span>
							)}
						</div>
						<div>
							{`Debt Date Context: `}
							{dateContextDebtData.error ? (
								<span className="text-error-red">{`${dateContextDebtData.error}`}</span>
							) : (
								<span>
									{dateContextDebtData.name} {dateContextDebtData.calculatedDate}
								</span>
							)}
						</div>
					</div>
				);
			default:
				return '';
		}
	};

	if (!displayCard) {
		return <div className={styles.errorMsg}>{`Unable to load card ${section.title} (${section._id})`}</div>;
	} else {
		return (
			<div className={styles.card}>
				<header>
					<div>{_get(displayCard, 'section.title', '')}</div>
					<span className={styles.iconSpan}>
						{!displayCard.error && (
							<KiFontIcon value="search" onClick={() => setSelectedCard(displayCard.card, 0)} />
						)}
						{!displayCard.error &&
							allowEdit && (
								<i
									title="Edit"
									className="material-icons"
									onClick={() => setSelectedCard(displayCard.card, 1)}
								>
									mode_edit
								</i>
							)}
						{deleteSection && (
							<i
								title="Remove"
								className={'material-icons ki-icon-rollover'}
								onClick={() => deleteSection(index)}
							>
								clear
							</i>
						)}
					</span>
				</header>
				<div className={styles.body}>
					{displayCard.error ? (
						<div key={section._id} className={styles.errorMsg}>{`${displayCard.error}`}</div>
					) : (
						<span>
							<div>Card Type: {displayCard.cardTypeLabel}</div>
							{bookmark && (
								<>
									<div>View Name: {bookmark.name}</div>
									{bookmark?.explorerData?.snapshotType && (
										<div>
											View Type:{' '}
											{
												explorationViewTypes.find(
													type => type.value === bookmark.explorerData.snapshotType
												)?.label
											}
										</div>
									)}
								</>
							)}
							<div>Funding Vehicle Override: {displayCard.fundingVehicleLabels}</div>
							<div>Scenario Override: {displayCard.scenarioLabel || 'N/A'}</div>
							<div>{getDateFromDateContext(displayCard, statementDate)}</div>
							<div>Display Type: Table</div>
						</span>
					)}
				</div>
			</div>
		);
	}
}

// NOTE - gonna need separate render function for summary, scenario, (drilldown), debt/asset

CardSummary.propTypes = {
	index: PropTypes.number,
	section: PropTypes.object,
	displayCard: PropTypes.object,
	setSelectedCard: PropTypes.func,
	statementDate: PropTypes.string,
	deleteSection: PropTypes.func,
	bookmark: PropTypes.object,
};

CardSummary.defaultProps = {};

export default CardSummary;
