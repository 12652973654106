import PropTypes from 'prop-types';
import React, {Component} from 'react';
import './SchemaColumn.scss';
import KiInput from 'components/KiInput';
import Select from 'react-select';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import validators from 'ki-common/validators';
import {dateTypes} from 'ki-common/enums';
const constraints = validators.schemaColumns.getConstraints();

const REQUIRED_COLUMNS = ['AssetID', 'Balance'];

const MESSAGE_CODES = {
	dataTypeMismatchNumeric: 'Data type mismatch',
	dataTypeMismatchShortDate: 'Data type mismatch',
	dataTypeMismatchLongDate: 'Data type mismatch',
	dataTypeMismatchCharacter: 'Data type mismatch',
	columnNotFound: 'Column Not Found',
	newColumnFound: 'New Column Found',
};

const REQUIRED_COLUMNS_DISPLAY_NAME_MAP = new Map([['AssetID', 'Asset ID'], ['Balance', 'Balance']]);

class ColumnMessages extends Component {
	//eslint-disable-line
	static propTypes = {
		messages: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired,
				code: PropTypes.oneOf([
					'dataTypeMismatchNumeric',
					'dataTypeMismatchShortDate',
					'dataTypeMismatchLongDate',
					'dataTypeMismatchCharacter',
					'columnNotFound',
					'newColumnFound',
				]).isRequired,
				level: PropTypes.oneOf(['info', 'warning', 'error']).isRequired,
				count: PropTypes.number,
			})
		),
	};

	render() {
		const {messages = []} = this.props;

		const filteredMessages = messages.filter(
			message => !['columnNotFound', 'newColumnFound'].includes(message.code)
		);
		return (
			<div className="messages">
				{filteredMessages.map(message => (
					<div key={message.id} className={`message level-${message.level}`}>
						{MESSAGE_CODES[message.code]}
					</div>
				))}
			</div>
		);
	}
}

class SchemaColumn extends Component {
	//eslint-disable-line
	static propTypes = {
		columnData: PropTypes.shape({
			id: PropTypes.string,
			dataType: PropTypes.oneOf(['date_short', 'date_long', 'numeric', 'string']),
			mapType: PropTypes.oneOf(['ignore', 'required', 'source']),
			mapColumn: PropTypes.string,
			displayName: PropTypes.string,
			defaultValue: PropTypes.any,
			previewData: PropTypes.array,
			messages: PropTypes.array,
			isNew: PropTypes.bool,
		}),
		focused: PropTypes.bool,
		onBlur: PropTypes.func,
		onFocus: PropTypes.func,
		onChange: PropTypes.func,
		mapColumns: PropTypes.array,
		isReadOnly: PropTypes.bool,
	};

	constructor(props) {
		super(props);
		const {
			columnData,
			columnData: {messages = []},
		} = this.props;
		this.state = {
			formValues: this.mapCustomDateFormValues(columnData),
			isNew: !!messages.find(m => m.code === 'newColumnFound'),
			isMissing: !!messages.find(m => m.code === 'columnNotFound'),
			hasErrors: messages.some(m => m.code !== 'newColumnFound' && m.level === 'error'),
			hasWarnings: messages.some(m => m.code !== 'newColumnFound' && m.level === 'warning'),
		};
	}

	componentDidMount() {
		const {
			columnData,
			columnData: {messages = []},
		} = this.props;
		this.setState({
			formValues: this.mapCustomDateFormValues(columnData),
			isNew: !!messages.find(m => m.code === 'newColumnFound'),
			isMissing: !!messages.find(m => m.code === 'columnNotFound'),
			hasErrors: messages.some(m => m.code !== 'newColumnFound' && m.level === 'error'),
			hasWarnings: messages.some(m => m.code !== 'newColumnFound' && m.level === 'warning'),
			validationErrors: validators.validate(this.mapCustomDateFormValues(columnData), constraints),
		});
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (this.props.focused !== nextProps.focused) {
			return true;
		} else if (this.state.formValues !== nextState.formValues) {
			return true;
		}
		return false;
	}

	componentDidUpdate(prevProps) {
		if (!_isEqual(prevProps, this.props)) {
			const {
				columnData,
				columnData: {messages = []},
			} = this.props;
			if (!_isEqual(prevProps.columnData, columnData)) {
				this.setState({
					formValues: this.mapCustomDateFormValues(columnData),
					isNew: !!messages.find(m => m.code === 'newColumnFound'),
					isMissing: !!messages.find(m => m.code === 'columnNotFound'),
					hasErrors: messages.some(m => m.code !== 'newColumnFound' && m.level === 'error'),
					hasWarnings: messages.some(m => m.code !== 'newColumnFound' && m.level === 'warning'),
					validationErrors: validators.validate(this.mapCustomDateFormValues(columnData), constraints),
				});
			}
		}
	}

	mapCustomDateFormValues = formValues => {
		const dateTypeMatch = dateTypes.get(formValues.format);
		if (dateTypeMatch) {
			return formValues;
		}
		const newValues = Object.assign({}, formValues);
		newValues.customFormat = formValues.format;
		newValues.format = '';
		return newValues;
	};

	handleBlur = e => {
		const {currentTarget, relatedTarget} = e;
		setTimeout(() => {
			if (!currentTarget.contains(relatedTarget)) {
				this.props.onBlur(this.state.formValues);
			}
		}, 0);
	};

	handleFocus = () => {
		setTimeout(() => {
			this.props.onFocus(this.props.columnData.id);
		}, 0);
	};

	setFormValue = (key, val, autoSave = true) => {
		if (val === this.state.formValues[key]) {
			return;
		}
		this.setState(
			state => {
				let validationResults;
				const formValues = {
					...state.formValues,
					[key]: val,
				};

				if (key === 'mapType') {
					if (val === 'required') {
						const mapColumnVal = _get(
							REQUIRED_COLUMNS.filter(c => !this.props.mapColumns.includes(c)),
							'[0]',
							null
						);
						formValues.mapColumn = mapColumnVal;
						const dataType = this.deriveDataType(mapColumnVal);
						formValues.dataType = dataType;
						formValues.defaultValue = this.deriveDefaultValue(dataType);
					} else {
						formValues.mapColumn = null;
					}
				} else if (key === 'mapColumn' && REQUIRED_COLUMNS_DISPLAY_NAME_MAP.has(val)) {
					const dataType = this.deriveDataType(val);
					formValues.dataType = dataType;
					formValues.defaultValue = this.deriveDefaultValue(dataType);
				} else if (key === 'dataType') {
					formValues.defaultValue = this.deriveDefaultValue(val);
				} else if (key === 'defaultValue') {
					validationResults = validators.validate(formValues, constraints);
				} else if (key === 'customFormat') {
					validationResults = validators.validate(formValues, constraints);
				}
				return {
					...state,
					validationErrors: validationResults,
					formValues,
				};
			},
			() => {
				if (autoSave) {
					this.props.onChange(this.state.formValues);
				}
			}
		);
	};

	deriveDataType = mapColumn => {
		switch (mapColumn) {
			case 'Balance':
				return 'numeric';
			default:
				return 'string';
		}
	};

	deriveDefaultValue = dataType => {
		switch (dataType) {
			case 'numeric':
				return '0';
			case 'date_long':
				return '01/01/1900';
			case 'date_short':
				return '01/1900';
			default:
				return '';
		}
	};

	onDataTypeChange = dataType => {
		this.setFormValue('dataType', dataType);
		this.setFormValue('format', '');
		this.setFormValue('customFormat', '');
	};

	buildDateSelectOptions = isLongDate => {
		const options = [{value: '', label: 'Custom'}];
		dateTypes.forEach((value, key) => {
			if (isLongDate) {
				if (value.type === 'date_long') {
					options.push({value: key, label: value.label});
				}
			} else if (value.type === 'date_short') {
				options.push({value: key, label: value.label});
			}
		});
		return options;
	};

	onCustomFormatChange = validationErrors => {
		if (!(validationErrors && validationErrors['customFormat'] && validationErrors.customFormat.length)) {
			this.props.onChange(this.state.formValues);
		}
	};

	render() {
		const {
			formValues: {dataType = 'string', mapType = 'ignore', mapColumn, displayName, defaultValue = ''},
			validationErrors = {},
			isNew,
			isMissing,
			hasErrors,
			hasWarnings,
		} = this.state;

		const {
			mapColumns = [],
			columnData: {id, messages = [], previewData = []},
			focused,
			isReadOnly,
		} = this.props;

		const shortDateOptions = this.buildDateSelectOptions(false);
		const longDateOptions = this.buildDateSelectOptions(true);

		const mapTypeOptions = [
			{
				value: 'ignore',
				label: 'Ignore',
			},
			{
				value: 'source',
				label: 'Source',
			},
			{
				value: 'required',
				label: 'Required',
				disabled: mapColumns.length === REQUIRED_COLUMNS.length,
			},
		];

		const mapColumnTypes = [
			{
				value: 'AssetID',
				label: 'AssetID',
				disabled: mapColumn !== 'AssetID' && mapColumns.includes('AssetID'),
			},
			{
				value: 'Balance',
				label: 'Balance',
				disabled: mapColumn !== 'Balance' && mapColumns.includes('Balance'),
			},
		];

		const dataTypeOptions = [
			{
				value: 'numeric',
				label: 'Numeric',
			},
			{
				value: 'string',
				label: 'String',
			},
			{
				value: 'date_short',
				label: 'Short Date',
			},
			{
				value: 'date_long',
				label: 'Long Date',
			},
		];

		return (
			<form
				className={`submission-schema-column${focused ? ' focused' : ''}${
					hasErrors ? ' error' : hasWarnings ? ' warning' : ''
				}`}
				onBlur={this.handleBlur}
				onFocus={this.handleFocus}
				tabIndex="0"
			>
				<header>
					{id}
					{isNew && <div className={'column-notice new'}>new</div>}
					{isMissing && <div className={'column-notice missing'}>missing</div>}
				</header>
				<KiInput
					name="displayName"
					type="text"
					disabled={isMissing || isReadOnly}
					value={displayName}
					onChange={val => this.setFormValue('displayName', val, false)}
					placeholder="Display Name"
					onBlur={() => this.props.onChange(this.state.formValues)}
				/>
				<div className="default-column-fields">
					<Select
						classNamePrefix="aut-select"
						name="mapType"
						value={mapTypeOptions.find(opt => opt.value === mapType)}
						className="map-type-selector"
						isDisabled={isMissing || isReadOnly}
						isClearable={false}
						isSearchable={false}
						options={mapTypeOptions}
						onChange={selection => this.setFormValue('mapType', selection.value)}
					/>
					{mapType === 'required' && (
						<Select
							classNamePrefix="aut-select"
							name="mapColumn"
							value={mapColumnTypes.find(opt => opt.value === mapColumn)}
							className="map-column-selector"
							isDisabled={isMissing || isReadOnly}
							isOptionDisabled={option => option.disabled}
							isClearable={false}
							isSearchable={false}
							options={mapColumnTypes}
							onChange={selection => this.setFormValue('mapColumn', selection.value)}
						/>
					)}
				</div>
				<div className="default-column-fields">
					<Select
						classNamePrefix="aut-select"
						className="data-type-selector"
						isDisabled={isMissing || mapType === 'required' || isReadOnly}
						isClearable={false}
						value={dataTypeOptions.find(opt => opt.value === dataType)}
						options={dataTypeOptions}
						onChange={selection => this.onDataTypeChange(selection.value)}
					/>
					<KiInput
						className="schema-input"
						name="defaultValue"
						type="text"
						disabled={isMissing || isReadOnly}
						value={defaultValue || ''}
						onChange={val => this.setFormValue('defaultValue', val, false)}
						placeholder={this.deriveDefaultValue(dataType)}
						onBlur={e => {
							if (!e.target.value) {
								this.setFormValue('defaultValue', this.deriveDefaultValue(dataType));
							} else {
								this.props.onChange(this.state.formValues);
							}
						}}
						error={
							validationErrors['defaultValue'] && validationErrors.defaultValue.length
								? validationErrors.defaultValue[0]
								: null
						}
					/>
				</div>
				{(dataType === 'date_short' || dataType === 'date_long') && (
					<div className="default-column-fields date-type-container">
						{dataType === 'date_short' && (
							<Select
								classNamePrefix="aut-select"
								className="data-type-selector"
								value={shortDateOptions.find(sdo => sdo.value === this.state.formValues.format)}
								isClearable={false}
								options={shortDateOptions}
								onChange={selection => this.setFormValue('format', selection.value)}
								isDisabled={isReadOnly}
							/>
						)}
						{dataType === 'date_long' && (
							<Select
								classNamePrefix="aut-select"
								className="data-type-selector custom-date-format-select"
								value={longDateOptions.find(ldo => ldo.value === this.state.formValues.format)}
								isClearable={false}
								options={longDateOptions}
								onChange={selection => this.setFormValue('format', selection.value)}
								isDisabled={isReadOnly}
							/>
						)}
						{this.state.formValues.format === '' && (
							<KiInput
								className="custom-date-format-input"
								name="customFormat"
								type="text"
								value={this.state.formValues.customFormat}
								onChange={val => this.setFormValue('customFormat', val, false)}
								placeholder={'M,D,Y and special chars'}
								onBlur={() => this.onCustomFormatChange(validationErrors)}
								error={
									validationErrors['customFormat'] && validationErrors.customFormat.length
										? 'Invalid inputs'
										: null
								}
								disabled={isReadOnly}
							/>
						)}
					</div>
				)}
				<ColumnMessages messages={messages} />
				<section className="data-preview">{previewData.map((row, idx) => <div key={idx}>{row}</div>)}</section>
			</form>
		);
	}
}

export default SchemaColumn;
