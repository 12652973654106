import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {FORECASTING_FLYOUT_MODE} from '../common/FlyoutSwitch';
import KiIconButton from '../../../../components/KiIconButton';
import styles from './ReplineForm.theme.scss';
import stylesForm from '../common/Form.theme.scss';
import {connect} from 'react-redux';
import KiSelect from '../../../../components/KiSelect';
import KiButton from '../../../../components/KiButton';
import KiInput from '../../../../components/KiInput';
import {getColumnsFromService} from '../../../../api/columnServiceApi';
import {useMergedState} from '../../../../utils/customHooks';
import CohortBands from '../../../../components/FlyoutManageViews/components/ViewForms/CohortBands';
import classNames from 'classnames';
import CohortBandsDate from '../../../../components/FlyoutManageViews/components/ViewForms/CohortBandsDate';
import {updateValue} from '../../../../components/FlyoutManageViews/components/ViewForms/actions';
import KiDnDList from '../../../../components/KiDnDList';
import ReplineRuleForm from './components/ReplineRuleForm';
import {moveInArray, removeFromArray, updateInArray} from '../../../../utils/arrayUtils';
import {getNotionalPools, getRepline} from '../../../../api/waterfallApi';
import CohortsLabel from './components/CohortsLabel';
import {showSnackbar} from 'state/actions/Snackbar';
import NameForm from '../common/NameForm';
import FormCloseSection from '../common/FormCloseSection';
import {saveRepline, getReplines, getAssumptions} from '../../../../api/waterfallApi';
import SidebarCreationSelect from '../common/SidebarCreationSelect';
import AssumptionForm from '../Assumptions/AssumptionForm';
import NotionalPoolForm from '../NotionalPools/NotionalPoolForm';
/* eslint-disable react/display-name */

const VALIDATION_MESSAGES = {
	DESCRIPTION: 'Description is required',
	DATASET_ID: 'Dataset is required',
	COHORTS: 'Please select at least one Cohort',
	BASE_ASSUMPTION: 'Base Assumption is required',
};

const NONE_OPTION = {value: null, label: 'None'};

const ReplineForm = ({editReplineId, setFlyoutMode, datasets, viewForm, updateValue, showSnackbar}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [formData, mergeFormData, setFormData] = useMergedState({
		name: '',
		description: '',
		datasetId: null,
		cohorts: [],
		baseAssumption: null,
		notionalPool: NONE_OPTION.value,
		rules: [],
	});
	const [validationErrors, mergeValidationErrors, setValidationErrors] = useMergedState({
		name: '',
		description: '',
		datasetId: '',
		cohorts: '',
		baseAssumption: '',
		rules: [],
	});
	const [replines, setReplines] = useState([]);
	const [assumptions, setAssumptions] = useState([]);
	const [notionalPools, setNotionalPools] = useState([]);
	const [cohorts, setCohorts] = useState([]);
	const [ruleParameters, setRuleParameters] = useState([]);
	const [selectedCohort, setSelectedCohort] = useState(null);
	const cohortSelectRef = useRef(null);

	useEffect(() => {
		setIsLoading(true);
		Promise.all([getReplines(), getAssumptions(), getNotionalPools()]).then(
			([replineData, assumptionData, notionalPoolsData]) => {
				setReplines(replineData);
				setAssumptions(assumptionData);
				setNotionalPools(notionalPoolsData);
				setIsLoading(false);
			}
		);
	}, []);

	const getColumnsForDataset = dataset => {
		if (dataset) {
			const params = {
				sources: {
					includeCohortColumns: true,
					includeAggregateColumns: true,
				},
				options: {
					embedColumns: true,
				},
			};
			getColumnsFromService(dataset, params).then(columns => {
				setCohorts(columns.filter(item => item.columnType === 'cohort'));
				setRuleParameters(
					columns.filter(
						item =>
							(item.columnType === 'cohort' && item.dataType === 'string') ||
							(item.columnType === 'aggregate' &&
								['date_short', 'date_long', 'numeric'].includes(item.dataType))
					)
				);
			});
		}
		mergeValidationErrors({cohorts: ''});
	};

	const isValueValid = value => (value && Array.isArray(value) === false) || (Array.isArray(value) && value.length);

	const initialValidation = repline => {
		setValidationErrors({
			name: null,
			description: isValueValid(repline.description) ? null : VALIDATION_MESSAGES.DESCRIPTION,
			datasetId: isValueValid(repline.datasetId) ? null : VALIDATION_MESSAGES.DATASET_ID,
			cohorts: isValueValid(repline.cohorts) ? null : VALIDATION_MESSAGES.COHORTS,
			baseAssumption: isValueValid(repline.baseAssumption) ? null : VALIDATION_MESSAGES.BASE_ASSUMPTION,
			rules: Array(repline.rules ? repline.rules.length : 0).fill(null),
		});
	};

	useEffect(
		() => {
			if (editReplineId) {
				getRepline(editReplineId).then(repline => {
					mergeFormData(repline);
					getColumnsForDataset(repline.datasetId);
					initialValidation(repline);
				});
			}
		},
		[editReplineId]
	);

	const updateCohortForm = cohort => {
		updateValue({name: 'granularity', value: cohort?.granularity || ''});
		updateValue({name: 'dateRangeStart', value: cohort?.dateRange?.start || ''});
		updateValue({name: 'dateRangeEnd', value: cohort?.dateRange?.end || ''});
		updateValue({name: 'bandsType', value: cohort?.bands?.type || 'default'});
		updateValue({name: 'bandsStep', value: cohort?.bands?.step || ''});
		updateValue({name: 'bandsSteps', value: cohort?.bands?.steps || []});
	};

	const isCohortBandAble = cohort => {
		return ['numeric', 'date_long', 'date_short'].includes(cohort.dataType);
	};

	const onCohortSelect = cohort => {
		if (!isCohortBandAble(cohort)) {
			return;
		}
		setSelectedCohort(cohort);
		const formCohort = formData.cohorts.find(c => c._id === cohort._id);
		updateCohortForm(formCohort);
	};

	const validateRequired = (value, propName, message) => {
		if (isValueValid(value)) {
			mergeValidationErrors({[propName]: null});
		} else {
			mergeValidationErrors({[propName]: message});
		}
	};

	const onDescriptionChange = description => {
		validateRequired(description, 'description', VALIDATION_MESSAGES.DESCRIPTION);
		mergeFormData({description});
	};

	const onDatasetChange = datasetId => {
		validateRequired(datasetId, 'datasetId', VALIDATION_MESSAGES.DATASET_ID);
		getColumnsForDataset(datasetId);
		setSelectedCohort(null);
		mergeFormData({datasetId, cohorts: [], rules: []});
		mergeValidationErrors({cohorts: '', rules: []});
	};

	const onCohortsChange = cohortsValue => {
		if (selectedCohort && !cohortsValue.find(item => item._id === selectedCohort._id)) {
			setSelectedCohort(null);
		}
		validateRequired(cohortsValue, 'cohorts', VALIDATION_MESSAGES.COHORTS);
		mergeFormData({cohorts: cohortsValue});
	};

	const onCohortFormReset = () => {
		updateCohortForm(null);
		const updatedCohorts = formData.cohorts.map(cohort => {
			if (selectedCohort && cohort._id === selectedCohort._id) {
				const newCohort = {...cohort};
				delete newCohort.bands;
				delete newCohort.granularity;
				delete newCohort.dateRange;

				return newCohort;
			}
			return cohort;
		});
		mergeFormData({cohorts: updatedCohorts});
		setSelectedCohort(null);
		showSnackbar('Banding removed from the selected cohort.');
	};

	const onCohortFormApply = () => {
		const updatedCohorts = formData.cohorts.map(cohort => {
			if (selectedCohort && cohort._id === selectedCohort._id) {
				if (selectedCohort.dataType === 'numeric') {
					return {
						...cohort,
						...{bands: viewForm.bands, granularity: viewForm.bands.step},
					};
				} else {
					return {
						...cohort,
						...{
							granularity: viewForm.granularity,
							dateRange: viewForm.dateRange,
						},
					};
				}
			}
			return cohort;
		});
		mergeFormData({cohorts: updatedCohorts});
		showSnackbar('Banding applied to the selected cohort.');
	};

	const onBaseAssumptionChange = baseAssumption => {
		validateRequired(baseAssumption, 'baseAssumption', VALIDATION_MESSAGES.BASE_ASSUMPTION);
		mergeFormData({baseAssumption});
	};

	const onRulesReordered = (items, fromIndex, toIndex) => {
		setFormData(data => ({...data, rules: moveInArray(data.rules, fromIndex, toIndex)}));
		setValidationErrors(errors => ({...errors, rules: moveInArray(errors.rules, fromIndex, toIndex)}));
	};

	const onRuleDelete = index => {
		setFormData(data => ({...data, rules: removeFromArray(data.rules, index)}));
		setValidationErrors(errors => ({...errors, rules: removeFromArray(errors.rules, index)}));
	};

	const onRuleChange = (rule, index, isValid) => {
		setFormData(data => ({...data, rules: updateInArray(data.rules, rule, index)}));
		setValidationErrors(errors => ({...errors, rules: updateInArray(errors.rules, isValid, index)}));
	};

	const reloadAssumptions = () => {
		setIsLoading(true);
		getAssumptions().then(assumptions => {
			setAssumptions(assumptions);
			setIsLoading(false);
		});
	};

	const reloadNotionalPools = () => {
		setIsLoading(true);
		getNotionalPools().then(pools => {
			setNotionalPools(pools);
			setIsLoading(false);
		});
	};

	const onAssumptionCreated = savedAssumption => {
		reloadAssumptions();
		onBaseAssumptionChange(savedAssumption._id);
	};

	const onNotionalPoolCreated = saved => {
		reloadNotionalPools();
		mergeFormData({notionalPool: saved._id});
	};

	const notionalPoolOptions = [NONE_OPTION, ...notionalPools.map(item => ({value: item._id, label: item.name}))];
	const rulesNotionalPools = formData.rules.map(item => item.notionalPool);

	const renderRuleItem = (item, index) => {
		return (
			<ReplineRuleForm
				rule={item}
				index={index}
				onDelete={onRuleDelete}
				parameters={ruleParameters}
				onRuleChange={onRuleChange}
				assumptions={assumptions}
				reloadAssumptions={reloadAssumptions}
				reloadNotionalPools={reloadNotionalPools}
				selectedBaseAssumption={formData.baseAssumption}
				notionalPoolOptions={notionalPoolOptions}
				selectedNotionalPools={[...rulesNotionalPools, formData.notionalPool]}
			/>
		);
	};

	const addNewRule = () => {
		setFormData(data => ({
			...data,
			rules: [
				...(data.rules || []),
				{
					filters: [{type: null, operator: null, filter: null, min: null, max: null}],
					assumptionKey: null,
					notionalPool: null,
				},
			],
		}));
		setValidationErrors(errors => ({...errors, rules: [...errors.rules, false]}));
	};

	const isFormValid = Object.keys(validationErrors)
		.map(key => {
			const error = validationErrors[key];
			if (error === null) {
				return true;
			} else if (Array.isArray(error)) {
				return !error.includes(false);
			}

			return false;
		})
		.reduce((acc, cur) => acc && cur);

	const onSubmit = () => {
		setIsLoading(true);
		saveRepline(formData).then(() => {
			showSnackbar('Repline saved successfully');
			setFlyoutMode(FORECASTING_FLYOUT_MODE.REPLINES_LIST);
		});
	};

	const datasetOptions = datasets.map(dataset => ({value: dataset.datasetId, label: dataset.name}));
	const datasetValue = datasetOptions.find(item => item.value === formData.datasetId);
	const cohortOptions = cohorts.map(cohort => ({value: cohort, label: cohort.displayName}));
	const cohortValue = cohortOptions.filter(
		obj => !!formData.cohorts.find(formCohort => formCohort._id === obj.value._id)
	);
	const rulesAssumptions = formData.rules.map(item => item.assumptionKey);
	const baseAssumptionOptions = assumptions.map(assumption => ({value: assumption._id, label: assumption.name}));
	const baseAssumptionValue = baseAssumptionOptions.find(item => item.value === formData.baseAssumption);
	const notionalPoolValue = notionalPoolOptions.find(item => item.value === formData.notionalPool);

	return (
		<section className={stylesForm.section}>
			<FormCloseSection onClose={() => setFlyoutMode(FORECASTING_FLYOUT_MODE.REPLINES_LIST)} />
			<form className={stylesForm.form}>
				<section className={stylesForm.scrollable}>
					<NameForm
						label="Repline"
						items={replines}
						mergeFormData={mergeFormData}
						mergeValidationErrors={mergeValidationErrors}
						nameFormData={formData.name}
						nameValidationErrors={validationErrors.name}
						editItemId={editReplineId}
					/>
					<div className="sidebar-form-section">
						<span className="form-instruction">Description</span>
						<KiInput
							className="substring-size-input"
							value={formData.description}
							onChange={onDescriptionChange}
							error={validationErrors.description}
						/>
					</div>
					<div className="sidebar-form-section">
						<span className="form-instruction">Dataset</span>
						<KiSelect
							value={datasetValue}
							options={datasetOptions}
							onChange={o => onDatasetChange(o.value)}
						/>
					</div>
					<div className="sidebar-form-section">
						<span className="form-instruction">Cohorts</span>
						<KiSelect
							isMulti
							components={{
								MultiValueLabel: props => (
									<CohortsLabel
										data={props}
										selectedCohort={selectedCohort}
										selectRef={cohortSelectRef}
										onCohortSelect={onCohortSelect}
										isCohortBandAble={isCohortBandAble}
									/>
								),
							}}
							selectRef={cohortSelectRef}
							value={cohortValue}
							options={cohortOptions}
							onChange={options =>
								onCohortsChange(Array.isArray(options) ? options.map(item => item.value) : [])
							}
							errorMessage={validationErrors.cohorts}
						/>
					</div>
					{selectedCohort && (
						<div className={classNames('sidebar-form-setting', styles.subFormSection)}>
							<header>
								<div>
									<p className="setting-name">Selected Cohort Banding</p>
								</div>
								<div className="setting-icons">
									<i title="Close" className="material-icons" onClick={() => setSelectedCohort(null)}>
										clear
									</i>
								</div>
							</header>
							{selectedCohort.dataType === 'numeric' && (
								<CohortBands setFormHasChanges={() => undefined} />
							)}
							{['date_long', 'date_short'].includes(selectedCohort.dataType) && (
								<CohortBandsDate setFormHasChanges={() => undefined} />
							)}
							<div className="inline-column-form-buttons">
								<KiButton mini flat primary onClick={onCohortFormReset}>
									Reset
								</KiButton>
								<KiButton mini raised primary onClick={onCohortFormApply}>
									Apply
								</KiButton>
							</div>
						</div>
					)}
					<div className="sidebar-form-section">
						<span className="form-instruction">Base Assumption</span>
						<SidebarCreationSelect
							value={baseAssumptionValue}
							onChange={option => onBaseAssumptionChange(option.value)}
							addLabel="Add New Assumption"
							options={baseAssumptionOptions}
							onCreated={onAssumptionCreated}
							isOptionDisabled={option => rulesAssumptions.includes(option.value)}
						>
							<AssumptionForm />
						</SidebarCreationSelect>
					</div>
					<div className="sidebar-form-section">
						<span className="form-instruction">Notional Pool</span>
						<SidebarCreationSelect
							value={notionalPoolValue}
							onChange={option => mergeFormData({notionalPool: option.value})}
							addLabel="Add New Notional Pool"
							options={notionalPoolOptions}
							onCreated={onNotionalPoolCreated}
							isOptionDisabled={option => option.value && rulesNotionalPools.includes(option.value)}
						>
							<NotionalPoolForm />
						</SidebarCreationSelect>
					</div>
					<div className="sidebar-form-section">
						<span className="form-instruction">Rules</span>
						{formData.rules && (
							<KiDnDList
								items={formData.rules.map((rule, index) => ({content: rule, id: index}))}
								onReorder={onRulesReordered}
								contentRenderFunc={renderRuleItem}
								isCombineEnabled={false}
								extendItemClass={stylesForm.dndItem}
							/>
						)}
						<div className={stylesForm.mainAddItem}>
							<KiIconButton
								icon="add_circle"
								id="addRuleButton"
								className={stylesForm.addItemButton}
								onClick={addNewRule}
								title="Click to add Rule"
							/>
							<label htmlFor="addRuleButton">Add Rules if needed</label>
							<div className={stylesForm.note}>
								Note: When the same asset is covered by multiple rules, the last rule takes precedence
							</div>
						</div>
					</div>
				</section>
				<div className="inline-column-form-buttons">
					<KiButton flat primary onClick={() => setFlyoutMode(FORECASTING_FLYOUT_MODE.REPLINES_LIST)}>
						Cancel
					</KiButton>
					<KiButton disabled={isLoading || !isFormValid} raised primary onClick={onSubmit}>
						Save
					</KiButton>
				</div>
			</form>
		</section>
	);
};

ReplineForm.propTypes = {
	editReplineId: PropTypes.string,
	setFlyoutMode: PropTypes.func.isRequired,
	datasets: PropTypes.array,
	updateValue: PropTypes.func.isRequired,
	isLoading: PropTypes.bool,
	viewForm: PropTypes.object,
	showSnackbar: PropTypes.func,
};

const mapStateToProps = state => ({
	datasets: state.datasetList.data,
	viewForm: state.viewForm,
});

const mapDispatchToProps = {
	updateValue,
	showSnackbar,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ReplineForm);
