import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {KiDatePicker, KiInput, KiSelect} from 'components';
import {components as SelectComponents} from 'react-select';
import {callOptions as CALL_OPTIONS} from 'ki-common/options/assumptions';
import {waterfallApi, fundingVehiclesApi, datasetFiltersApi} from 'api';
import styles from './ScenarioFormModal.theme.scss';
import {addProjectionScenario, updateProjectionScenario} from '../../../actions';
import {connect} from 'react-redux';
import {isObject} from 'validate.js';
import KiModal from '../../../../../components/KiModal';
import {updateInArray} from '../../../../../utils/arrayUtils';

const PRICING_VALUES = {
	NONE: 'none',
	ALL: 'all',
	CUSTOM: 'custom',
};

const PRICING_STARTING_OPTIONS = [
	{value: PRICING_VALUES.NONE, label: 'None'},
	{value: '', label: 'Please select model for more options', disabled: true},
];

export const PRICING_FULL_OPTIONS = [
	{value: PRICING_VALUES.NONE, label: 'None'},
	{value: PRICING_VALUES.ALL, label: 'Apply All Tranches'},
	{value: PRICING_VALUES.CUSTOM, label: 'Custom'},
];

const SelectContainer = ({children, ...props}) => {
	return (
		<SelectComponents.SelectContainer {...props} className={styles.selectContainer}>
			{!!props.selectProps.label && <label>{props.selectProps.label}</label>}
			{children}
		</SelectComponents.SelectContainer>
	);
};
SelectContainer.propTypes = {
	children: PropTypes.node,
	selectProps: PropTypes.any,
};

const ScenarioFormModal = props => {
	const noneOption = {_id: 'None', name: 'None'};
	const [name, setName] = useState(props.scenario.name);
	const [models, setModels] = useState(props.models || [noneOption]);
	const [entities, setEntities] = useState(props.entities || []);
	const [replines, setReplines] = useState([]);
	const [filtersOptions, setFiltersOptions] = useState([]);
	const [filter, setFilter] = useState(props.scenario?.filters?.[0] || null);
	const [pricingAssumptionOptions, setPricingAssumptionOptions] = useState([]);
	const [tranches, setTranches] = useState([]);
	const [collateral, setCollateral] = useState(props.scenario.collateral || []);
	const [model, setModel] = useState(props.scenario?.model);
	const [repline, setRepline] = useState(null);
	const [pricing, setPricing] = useState(props.scenario?.pricing || null);
	const [pricingOptions, setPricingOptions] = useState(PRICING_STARTING_OPTIONS);
	const [pricingAssumptionId, setPricingAssumptionId] = useState(props.scenario?.pricingAssumptionId || null);
	const [pricingMapping, setPricingMapping] = useState(props.scenario?.pricingMapping || []);
	const [call, setCall] = useState(CALL_OPTIONS[0]);
	const [callDate, setCallDate] = useState(props.scenario?.callDate);
	const [dataset, setDataset] = useState(props.scenario?.dataset);
	const [hypoCollateral, setHypoCollateral] = useState(null);

	useEffect(() => {
		waterfallApi.getPricingAssumptions().then(assumptions => {
			setPricingAssumptionOptions(assumptions.map(item => ({label: item.name, value: item._id})));
		});
	}, []);

	useEffect(
		() => {
			(async () => {
				if (props.scenario) {
					if (props.scenario?.repline?._id) {
						setRepline(replines.find(x => x._id === props.scenario?.repline?._id));
					}
					if (props.scenario.call?._id) {
						setCall(CALL_OPTIONS.find(x => x.value === props.scenario.call?._id));
						setCallDate(props.scenario?.callDate);
					}
				}
			})();
		},
		[props.scenario]
	);

	useEffect(
		() => {
			if (dataset?.datasetId) {
				waterfallApi.getEntitiesForDatasetId(dataset.datasetId).then(fetchedEntities => {
					setEntities(fetchedEntities);
					setCollateral(
						fetchedEntities.filter(fetched => !!collateral?.find(item => item._id === fetched._id))
					);
				});
				waterfallApi
					.getReplinesForDatasetId(dataset.datasetId)
					.then(fetchedReplines => setReplines(fetchedReplines));
				datasetFiltersApi.fetchMany(dataset.datasetId).then(fetchedFilters => {
					setFiltersOptions([noneOption, ...fetchedFilters]);
					setFilter(fetchedFilters.find(item => item._id === filter?._id) || null);
				});
			}
		},
		[dataset]
	);

	useEffect(
		() => {
			(async () => {
				const fvId = collateral?.[0]?.fvId;
				if (collateral?.length === 1 && fvId) {
					const fetchedModels = await waterfallApi.getModels(fvId);
					const modelOptions = [noneOption, ...fetchedModels];
					setModels(modelOptions);
					setModel(modelOptions.find(x => x._id === model?._id));

					const fvSettings = await fundingVehiclesApi.fetchFundingVehicleSettings(fvId);
					const tranches = fvSettings.filter(item => item.rowType === 'debt');
					setTranches(tranches);
				} else {
					setModels([noneOption]);
					setModel(noneOption);
				}
			})();
		},
		[collateral]
	);

	useEffect(
		() => {
			if (filter && filter._id !== noneOption._id) {
				setCollateral([]);
			}
		},
		[filter]
	);

	useEffect(
		() => {
			if (props.scenario?.repline?._id) {
				setRepline(replines.find(x => x._id === props.scenario?.repline?._id));
			}
		},
		[replines]
	);

	const resetPricing = (setToNone = false) => {
		setPricing(setToNone ? PRICING_VALUES.NONE : null);
		setPricingAssumptionId(null);
		setPricingMapping([]);
	};

	useEffect(
		() => {
			if (model && model._id !== 'None') {
				setPricingOptions(PRICING_FULL_OPTIONS);
			} else {
				resetPricing(true);
				setPricingOptions(PRICING_STARTING_OPTIONS);
				setCall(CALL_OPTIONS[0]);
			}
		},
		[model]
	);

	const onDatasetChange = value => {
		setDataset(value);
		resetPricing();
	};

	const onCollateralChange = value => {
		const hypo = value?.find(item => item.type === 'hypo');
		setHypoCollateral(hypo);
		setCollateral(value);
	};

	const onPricingChange = value => {
		if (pricing === PRICING_VALUES.ALL && value !== PRICING_VALUES.ALL) {
			setPricingAssumptionId(null);
		}
		if (value === PRICING_VALUES.CUSTOM) {
			setPricingMapping(tranches.map(item => ({trancheId: item._id, assumptionId: null})));
		} else if (pricing === PRICING_VALUES.CUSTOM) {
			setPricingMapping([]);
		}
		setPricing(value);
	};

	const onPricingMappingChange = (trancheId, value, index) => {
		setPricingMapping(mapping => {
			return updateInArray(mapping, {trancheId, assumptionId: value}, index);
		});
	};

	const isNameUnique = !props.scenarios
		.filter(s => s._id !== props.scenario?._id)
		.map(s => s.name)
		.includes(name);

	const handleSave = () => {
		const toSave = {
			...(props.scenario?._id && {_id: props.scenario._id}),
			name: name,
			dataset,
			collateral,
			filters: filter ? [filter] : [],
			model: {_id: model._id, name: model.name},
			pricing,
			pricingAssumptionId,
			pricingMapping,
			repline: {_id: repline._id, name: repline.name},
			call: {_id: call.value, name: call.label},
			callDate: callDate && isObject(callDate) ? callDate.toISOString().slice(0, 10) : callDate,
		};
		if (!toSave._id) {
			return props.addProjectionScenario(toSave).then(() => props?.onClose?.());
		}
		return props.updateProjectionScenario(toSave).then(() => props?.onClose?.());
	};

	const saveEnabled =
		name &&
		isNameUnique &&
		dataset !== '' &&
		((collateral && collateral.length) || (filter && filter._id !== noneOption._id)) &&
		pricing &&
		(pricing !== PRICING_VALUES.ALL || pricingAssumptionId) &&
		(pricing !== PRICING_VALUES.CUSTOM || pricingMapping.every(item => item.assumptionId !== null)) &&
		repline &&
		call &&
		(call?._id !== 'User Selected' || callDate);

	const isOneCollateral = collateral?.length === 1;
	const isFilterSelected = filter && filter._id !== noneOption._id;

	return (
		<KiModal
			className={styles.modal}
			bodyClassName={styles.modalBody}
			header={props.scenario.name ? 'Edit scenario' : 'Create new scenario'}
			active={props.isActive}
			showCloseIcon={true}
			actions={[
				{
					label: 'Cancel',
					onClick: props.onClose,
				},
				{
					label: 'Save',
					disabled: !saveEnabled,
					onClick: handleSave,
				},
			]}
			onClose={props.onClose}
		>
			<div className={styles.formBox}>
				<KiInput
					value={name || ''}
					onChange={val => setName(val)}
					floating={false}
					error={
						(props.scenario._id && !name && 'Name is required') ||
						(name && !isNameUnique && 'Name is not unique')
					}
					placeholder="Scenario Name"
				/>
				<KiSelect
					label="Dataset"
					components={{SelectContainer}}
					value={props.datasets.filter(x => dataset?.datasetId === x.datasetId)}
					getOptionValue={opt => opt.datasetId}
					getOptionLabel={opt => opt.name}
					options={props.datasets}
					onChange={option => onDatasetChange(option)}
				/>
				<KiSelect
					label="Collateral"
					isMulti={true}
					components={{SelectContainer}}
					value={entities.filter(x => !!collateral?.find(item => item._id === x._id))}
					noOptionsMessage={() => {
						if (!dataset) return 'No dataset selected';
						return `Dataset ${dataset?.name} contains no collaterals`;
					}}
					getOptionLabel={opt => opt.name}
					getOptionValue={opt => opt._id}
					options={entities}
					onChange={option => onCollateralChange(option)}
					isOptionDisabled={option =>
						hypoCollateral && option.type === 'hypo' && hypoCollateral._id !== option._id
					}
					isDisabled={isFilterSelected}
					placeholder={isFilterSelected ? 'None' : 'Select...'}
				/>
				<KiSelect
					label="Filter"
					components={{SelectContainer}}
					value={filtersOptions.find(x => x._id === filter?._id) || null}
					options={filtersOptions}
					getOptionLabel={opt => opt.name}
					getOptionValue={opt => opt._id}
					onChange={option => setFilter(option)}
				/>
				<KiSelect
					label="Model"
					components={{SelectContainer}}
					value={models.filter(x => x._id === model?._id)}
					options={models}
					noOptionsMessage={() => 'No models available'}
					getOptionLabel={opt => opt.name}
					getOptionValue={opt => opt._id}
					onChange={option => setModel(option)}
					isDisabled={isFilterSelected || !isOneCollateral}
				/>
				<KiSelect
					label="Repline Set"
					components={{SelectContainer}}
					value={replines.filter(x => x._id === repline?._id)}
					options={replines}
					getOptionLabel={opt => opt.name}
					getOptionValue={opt => opt._id}
					onChange={option => setRepline(option)}
				/>
				<KiSelect
					label="Pricing"
					components={{SelectContainer}}
					value={pricingOptions.filter(x => x.value === pricing)}
					options={pricingOptions}
					onChange={option => onPricingChange(option.value)}
					isOptionDisabled={option => option.disabled}
					isDisabled={isFilterSelected || !isOneCollateral}
				/>
				{pricing === PRICING_VALUES.ALL && (
					<KiSelect
						label="Pricing Assumption"
						components={{SelectContainer}}
						value={pricingAssumptionOptions.filter(x => x.value === pricingAssumptionId)}
						options={pricingAssumptionOptions}
						onChange={option => setPricingAssumptionId(option.value)}
					/>
				)}
				{pricing === PRICING_VALUES.CUSTOM && (
					<div className={styles.mappingContainer}>
						<label>Pricing Assumption</label>
						<div className={styles.mappingBox}>
							{tranches && tranches.length ? (
								tranches.map((item, index) => (
									<KiSelect
										key={index}
										label={`Tranche: ${item.name}`}
										components={{SelectContainer}}
										value={pricingAssumptionOptions.filter(
											x => x.value === pricingMapping[index]?.assumptionId
										)}
										options={pricingAssumptionOptions}
										onChange={option => onPricingMappingChange(item._id, option.value, index)}
									/>
								))
							) : (
								<p>There are no tranches for selected collaterals</p>
							)}
						</div>
					</div>
				)}
				<KiSelect
					components={{SelectContainer}}
					label="Call"
					value={CALL_OPTIONS.filter(x => x.value === call?.value)}
					options={CALL_OPTIONS}
					onChange={option => setCall(option)}
					isDisabled={isFilterSelected || model?.name === 'None'}
				/>
				{call?.value === 'User Defined' &&
					model.name != 'None' && (
						<KiDatePicker
							className={styles.datePicker}
							label="Call Date"
							minDate={new Date()}
							excludeDates={[new Date()]}
							value={callDate}
							onChange={value => setCallDate(value)}
						/>
					)}
			</div>
		</KiModal>
	);
};

ScenarioFormModal.propTypes = {
	isActive: PropTypes.bool.isRequired,
	scenario: PropTypes.object,
	scenarios: PropTypes.array,
	datasets: PropTypes.array,
	entities: PropTypes.array,
	models: PropTypes.array,
	onClose: PropTypes.func,
	addProjectionScenario: PropTypes.func.isRequired,
	updateProjectionScenario: PropTypes.func.isRequired,
};

ScenarioFormModal.defaultProps = {
	datasets: [],
	scenarios: [],
	scenario: {},
};

const mapStateToProps = state => ({
	datasets: state.datasetList.data,
	scenarios: state.forecasting.projectionScenarios,
});

const mapDispatchToProps = {
	addProjectionScenario,
	updateProjectionScenario,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ScenarioFormModal);
