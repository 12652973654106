import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './KiButtonGroup.theme.scss';

export class KiButtonGroup extends Component {
	static propTypes = {
		value: PropTypes.any,
		onChange: PropTypes.func,
		children: PropTypes.node,
		style: PropTypes.object,
		className: PropTypes.string,
		disabled: PropTypes.bool,
	};

	static defaultProps = {
		style: {},
		className: '',
	};

	getStylesForIndex = location => {
		switch (location) {
			case 'first':
				return {
					borderRadius: '.25em 0 0 .25em',
				};
			case 'last':
				return {
					borderRadius: '0 .25em .25em 0',
				};
			default:
				return {
					borderRadius: 0,
				};
		}
	};

	render() {
		const children = React.Children.map(this.props.children, child => {
			return React.cloneElement(child, {
				onMouseUp: () => (this.props.disabled ? () => null : this.props.onChange(child.props.value)),
				primary: child.props.value === this.props.value,
				style: {borderRadius: 0},
				disabled: child.props.value !== this.props.value && this.props.disabled,
			});
		});
		return (
			<div className={`${styles.root} ${this.props.className}`} style={this.props.style}>
				{children}
			</div>
		);
	}
}

export default KiButtonGroup;
