import {_handleResponse, _getHeaders, apiUrl} from 'api/apiUtils';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
export const fetchModels = async datasetId => {
	const fetchResult = await fetch(`${apiUrl}/fundingOptimization/fundingModels?datasetId=${datasetId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);

	return fetchResult;
};

export const fetchModel = async modelId => {
	const fetchResult = await fetch(`${apiUrl}/fundingOptimization/fundingModels/${modelId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);

	return fetchResult;
};

export const upsertModel = async model => {
	let fetchResult;
	if (model._id) {
		fetchResult = await fetch(`${apiUrl}/fundingOptimization/fundingModels/${model._id}`, {
			credentials: 'include',
			method: 'PATCH',
			headers: _getHeaders('PATCH'),
			body: JSON.stringify(model),
		}).then(_handleResponse);
	} else {
		fetchResult = await fetch(`${apiUrl}/fundingOptimization/fundingModels`, {
			credentials: 'include',
			method: 'POST',
			headers: _getHeaders('POST'),
			body: JSON.stringify(model),
		}).then(_handleResponse);
	}

	return fetchResult;
};

export const updateModel = async model => {
	const fetchResult = await fetch(`${apiUrl}/fundingOptimization/fundingModels`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify(model),
	});

	return fetchResult;
};

export const copyModel = async model => {
	const fetchResult = await fetch(`${apiUrl}/fundingOptimization/fundingModels/copy/${model._id}`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(model),
	}).then(_handleResponse);

	return fetchResult;
};

export const deleteModel = async modelId => {
	const fetchResult = await fetch(`${apiUrl}/fundingOptimization/fundingModels/${modelId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	}).then(_handleResponse);

	return fetchResult;
};

export const deleteConstraintGroup = async id =>
	fetch(`${apiUrl}/fundingOptimization/constraintGroups/${id}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	}).then(_handleResponse);

export const upsertConstraintGroup = async constraint => {
	let fetchResult;
	if (constraint._id) {
		fetchResult = await fetch(`${apiUrl}/fundingOptimization/constraintGroups/${constraint._id}`, {
			credentials: 'include',
			method: 'PATCH',
			headers: _getHeaders('PATCH'),
			body: JSON.stringify(constraint),
		}).then(_handleResponse);
	} else {
		fetchResult = await fetch(`${apiUrl}/fundingOptimization/constraintGroups`, {
			credentials: 'include',
			method: 'POST',
			headers: _getHeaders('POST'),
			body: JSON.stringify(constraint),
		}).then(_handleResponse);
	}

	return fetchResult;
};

export const updateConstraintGroup = async constraint => {
	const fetchResult = await fetch(`${apiUrl}/fundingOptimization/constraintGroups/${constraint._id}`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(constraint),
	}).then(_handleResponse);

	return fetchResult;
};

export const getConstraintGroups = async modelId => {
	const fetchResult = await fetch(`${apiUrl}/fundingOptimization/constraintGroups?modelId=${modelId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);

	return fetchResult;
};

export const fetchScenarios = async (
	datasetId,
	hydrateNames = true,
	hydrateStatus = true,
	includeRecurring = false
) => {
	const url = `${apiUrl}/fundingOptimization/scenarios?datasetId=${datasetId}&hydrateNames=${hydrateNames}&hydrateStatus=${hydrateStatus}&includeRecurring=${includeRecurring}`;
	const fetchResult = await fetch(url, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);

	return fetchResult;
};

export const fetchScenario = async scenarioId => {
	const fetchResult = await fetch(`${apiUrl}/fundingOptimization/scenarios/${scenarioId}?hydrateStatus=true`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);

	return fetchResult;
};

export const upsertScenario = async scenario => {
	let fetchResult;
	if (scenario._id) {
		fetchResult = await fetch(`${apiUrl}/fundingOptimization/scenarios/${scenario._id}`, {
			credentials: 'include',
			method: 'PATCH',
			headers: _getHeaders('PATCH'),
			body: JSON.stringify(scenario),
		}).then(_handleResponse);
	} else {
		fetchResult = await fetch(`${apiUrl}/fundingOptimization/scenarios`, {
			credentials: 'include',
			method: 'POST',
			headers: _getHeaders('POST'),
			body: JSON.stringify(scenario),
		}).then(_handleResponse);
	}

	return fetchResult;
};

export const deleteScenario = async scenarioId => {
	const fetchResult = await fetch(`${apiUrl}/fundingOptimization/scenarios/${scenarioId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	}).then(() => {
		_handleResponse;
	});

	return fetchResult;
};

export const optimizeScenario = async scenarioId => {
	const fetchResult = await fetch(`${apiUrl}/fundingOptimization/scenarios/${scenarioId}/optimize`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);

	return fetchResult;
};

export const getScenarioStatus = async scenarioId => {
	const fetchResult = await fetch(`${apiUrl}/fundingOptimization/scenarios/${scenarioId}/messages`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);

	return fetchResult;
};

export const cancelOptimizeScenario = async scenarioId => {
	const fetchResult = await fetch(`${apiUrl}/fundingOptimization/scenarios/${scenarioId}/cancel`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);

	return fetchResult;
};

export const rejectScenario = async scenarioId => {
	const fetchResult = await fetch(`${apiUrl}/fundingOptimization/scenarios/${scenarioId}/reject`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);

	return fetchResult;
};

export const approveScenario = async scenarioId => {
	const fetchResult = await fetch(`${apiUrl}/fundingOptimization/scenarios/${scenarioId}/approve`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);

	return fetchResult;
};

export const submitScenario = async scenarioId => {
	const fetchResult = await fetch(`${apiUrl}/fundingOptimization/scenarios/${scenarioId}/submit`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);

	return fetchResult;
};

export const fetchBookmarks = async datasetId => {
	const fetchResult = await fetch(`${apiUrl}/fundingOptimization/bookmarks/dataset/${datasetId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);

	return fetchResult;
};

export const fetchBookmarkById = async bookmarkId => {
	const fetchResult = await fetch(`${apiUrl}/fundingOptimization/bookmarks/${bookmarkId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);

	return fetchResult;
};

export const fetchDefaultBookmark = async (datasetId, tableType, scenarioId) => {
	let path = `${apiUrl}/fundingOptimization/bookmarks/default/?datasetId=${datasetId}&tableType=${tableType}`;
	if (scenarioId) {
		path += `&scenarioId=${scenarioId}`;
	}
	const fetchResult = await fetch(path, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);

	return fetchResult;
};

export const deleteBookmark = async viewId => {
	const fetchResult = await fetch(`${apiUrl}/fundingOptimization/bookmarks/${viewId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	}).then(_handleResponse);

	return fetchResult;
};

export const upsertBookmark = async view => {
	let fetchResult;
	if (view._id) {
		fetchResult = await fetch(`${apiUrl}/fundingOptimization/bookmarks/${view._id}`, {
			credentials: 'include',
			method: 'PATCH',
			headers: _getHeaders('PATCH'),
			body: JSON.stringify(view),
		}).then(_handleResponse);
	} else {
		fetchResult = await fetch(`${apiUrl}/fundingOptimization/bookmarks`, {
			credentials: 'include',
			method: 'POST',
			headers: _getHeaders('POST'),
			body: JSON.stringify(view),
		}).then(_handleResponse);
	}

	return fetchResult;
};

export const updateScenarioTransferDate = async (scenarioId, date) => {
	// /fundingOptimization/scenarios/:scenarioId/transferDate/:transferDate
	const fetchResult = await fetch(`${apiUrl}/fundingOptimization/scenarios/${scenarioId}/transferDate/${date}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);

	return fetchResult;
};

export const fetchScenarioAnalysis = async (bookmarkId, scenarioId) => {
	//transferDate
	// /fundingOptimization/analysis
	// POST with bookmark as the body OR GET with ?bookmarkId=some-id-here
	// Can pass scenarioId or transferDate as params
	const fetchResult = await fetch(
		`${apiUrl}/fundingOptimization/analysis?bookmarkId=${bookmarkId}&scenarioId=${scenarioId}`,
		{
			credentials: 'include',
			headers: _getHeaders(),
		}
	).then(_handleResponse);
	return fetchResult;
};

export const fetchScenarioAnalysisPost = async (bookmark, scenarioId) => {
	//, transferDate
	// /fundingOptimization/analysis
	// POST with bookmark as the body OR GET with ?bookmarkId=some-id-here
	// Can pass scenarioId or transferDate as params

	// Only include the scenarioId if it is passed
	const url = `${apiUrl}/fundingOptimization/analysis${scenarioId ? `?scenarioId=${scenarioId}` : ''}`;
	const fetchResult = await fetch(url, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(bookmark),
	}).then(_handleResponse);
	return fetchResult;
};

export const fetchSummaryAnalysisPost = async (bookmark, transferDate) => {
	// Can pass scenarioId or transferDate as params
	let path = `${apiUrl}/fundingOptimization/analysis`;
	if (transferDate) {
		path += `?transferDate=${transferDate}`;
		bookmark.settings.scenarioType = 'lastCommitted';
	}
	if (!bookmark.settings.statementDate) {
		bookmark.settings.statementDate = dateToShortDate(new Date());
	}
	const fetchResult = await fetch(path, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(bookmark),
	}).then(_handleResponse);
	return fetchResult;
};

export const fetchFundingAnalysis = async bookmark => {
	const fetchResult = await fetch(`${apiUrl}/fundingOptimization/analysis`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(bookmark),
	}).then(_handleResponse);
	return fetchResult;
};

// TODO seems like a rought duplicate of ki-website/app/api/explorerApi.js fetchScenarioList
export const fetchQuickFilters = async (
	datasetId,
	snapshotDate = this.state.bookmark.settings.snapshotDate,
	dateContext,
	fvAnalysis = 'summary'
) => {
	let path = `${apiUrl}/explore/quickFilterLists/?datasetId=${datasetId}
		&fvAnalysis=${fvAnalysis}
		&snapshotDate=${snapshotDate}
		&scenarioType=assetSnapshot`;
	if (dateContext) {
		path = `${path}&dateContext=${dateContext}`;
	}

	const fetchResult = await fetch(path, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);

	if (!fetchResult.transferDates.length) {
		fetchResult.transferDates = ['No Pending Found'];
	}
	return {
		scenarioList: [
			{
				name: 'Start of Day',
				_id: 'assetSnapshot',
			},
			{
				name: 'End of Day',
				_id: 'lastApproved',
			},
			{
				label: 'Pending',
				options: fetchResult.transferDates.map(tDate => {
					if (tDate === 'No Pending Found') {
						return {
							name: tDate,
							type: 'transferDate',
							_id: tDate,
							disabled: true,
						};
					} else {
						return {
							name: tDate,
							type: 'transferDate',
							_id: tDate, // actual transferDate
						};
					}
				}),
			},
		],
	};
};

export const getOrderRecurringScenarios = async (datasetId, hydrateScenarios) => {
	let url = `${apiUrl}/fundingOptimization/scenarios/recurringPriority/dataset/${datasetId}`;
	if (hydrateScenarios === true) {
		url = `${url}?hydrateScenarios=true`;
	}
	const fetchResult = await fetch(url, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
	return fetchResult;
};

export const setOrderRecurringScenarios = async (datasetId, recurring, recurringApproved) => {
	const url = `${apiUrl}/fundingOptimization/scenarios/recurringPriority/dataset/${datasetId}`;
	const fetchResult = await fetch(url, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify({
			recurring,
			recurringApproved,
		}),
	}).then(_handleResponse);
	return fetchResult;
};

export default {
	fetchModels,
	fetchModel,
	upsertModel,
	copyModel,
	updateModel,
	deleteModel,
	upsertConstraintGroup,
	updateConstraintGroup,
	getConstraintGroups,
	fetchScenarios,
	fetchScenario,
	upsertScenario,
	deleteScenario,
	optimizeScenario,
	getScenarioStatus,
	cancelOptimizeScenario,
	rejectScenario,
	approveScenario,
	submitScenario,
	fetchDefaultBookmark,
	fetchBookmarkById,
	fetchBookmarks,
	deleteBookmark,
	upsertBookmark,
	updateScenarioTransferDate,
	fetchScenarioAnalysis,
	fetchScenarioAnalysisPost,
	fetchSummaryAnalysisPost,
};
