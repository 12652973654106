import PropTypes from 'prop-types';
import React, {Component} from 'react';
import styles from './FlyoutFilters.theme.scss';
import {datasetFiltersApi} from 'api';
import {getColumnsFromService} from 'api/columnServiceApi';
import {connect} from 'react-redux';
import FilterDetail from './components/FilterDetail';
import FilterList from './components/FilterList.js';
import {fetchDataset} from 'containers/datasetList/actions';
import {fetchAccounts} from 'containers/accounts/actions';
import {showSnackbar} from 'state/actions/Snackbar';

export class FlyoutFilters extends Component {
	static propTypes = {
		filters: PropTypes.array,
		datasetId: PropTypes.string,
		user: PropTypes.object,
		fetchDataset: PropTypes.func.isRequired,
		userList: PropTypes.array,
		fetchAccounts: PropTypes.func,
		showSnackbar: PropTypes.func,
		bookmarkId: PropTypes.string,
	};

	static defaultProps = {
		filters: [],
	};

	state = {
		existingTags: [],
		filters: this.props.filters,
		assetColumns: [],
		mode: 'view',
	};

	componentDidMount = () => {
		if (this.props.user.groups.includes('SystemAdmins')) {
			this.props.fetchAccounts();
		}
		this.updateAssetColumnsAndFilters(this.props.datasetId);
	};

	componentDidUpdate(prevProps) {
		if (this.props.datasetId && this.props.datasetId !== prevProps.datasetId) {
			this.updateAssetColumnsAndFilters(this.props.datasetId);
		}
	}

	async updateAssetColumnsAndFilters(datasetId) {
		if (datasetId) {
			await this.fetchFilters(datasetId);
			const assetColumns = await getColumnsFromService(datasetId, {
				sources: {
					includeAssetColumns: true,
					includeAssetCalculations: true,
					includeDateColumns: true,
				},
			});
			return new Promise(resolve => this.setState({assetColumns}, resolve));
		}
		return Promise.reject(new Error('No datasetId provided'));
	}

	removeFilter = filterId => {
		return datasetFiltersApi
			.removeOne(filterId)
			.then(() => {
				this.props.showSnackbar('Filter removed');
				return this.fetchFilters(this.props.datasetId);
			})
			.then(() => this.props.fetchDataset(this.props.datasetId));
	};

	updateFilter = filter => {
		return datasetFiltersApi
			.updateOne(filter)
			.then(() => {
				this.props.showSnackbar('Filter updated');
				return this.fetchFilters(this.props.datasetId);
			})
			.then(() => this.props.fetchDataset(this.props.datasetId));
	};

	createFilter = filter => {
		return datasetFiltersApi
			.createOne(filter)
			.then(() => {
				this.props.showSnackbar('Filter created');
				return this.fetchFilters(this.props.datasetId);
			})
			.then(() => this.props.fetchDataset(this.props.datasetId));
	};

	async fetchFilters(datasetId) {
		const filters = await datasetFiltersApi.fetchMany(datasetId);
		const existingTags = [...new Set(filters.flatMap(filter => filter.tags))];
		return await new Promise(resolve => {
			this.setState(
				{
					filters,
					existingTags,
					mode: 'view',
				},
				resolve
			);
		});
	}

	render() {
		const isAdmin = this.props.user.groups.findIndex(g => g === 'SystemAdmins') >= 0;
		return (
			<article className={styles.root}>
				<header className="flyout-panel-title">MANAGE FILTERS</header>
				<FilterDetail
					active={['create', 'edit'].includes(this.state.mode)}
					existingTags={this.state.existingTags}
					onCreateFilter={this.createFilter}
					onUpdateFilter={this.updateFilter}
					filter={this.state.filter}
					assetColumns={this.state.assetColumns}
					datasetId={this.props.datasetId}
					onModeChange={val =>
						this.setState({
							mode: val,
							filter: val === 'edit' ? this.state.filter : null,
						})
					}
					user={this.props.user}
					mode={this.state.mode}
					isAdmin={isAdmin}
				/>
				<FilterList
					active={this.state.mode === 'view'}
					filters={this.state.filters}
					searchValue={this.state.searchValue}
					onNewFilterClick={() => this.setState({mode: 'create', filter: null})}
					editFilter={filter => this.setState({mode: 'edit', filter: filter})}
					cloneFilter={filter =>
						this.setState({
							mode: 'create',
							filter: {...filter, _id: undefined, name: `${filter.name} copy`},
						})
					}
					removeFilter={this.removeFilter}
					user={this.props.user}
					userList={this.props.userList}
					isAdmin={isAdmin}
					showSnackbar={this.props.showSnackbar}
				/>
			</article>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user,
	userList: state.accounts.data,
});

export default connect(
	mapStateToProps,
	{fetchDataset, fetchAccounts, showSnackbar}
)(FlyoutFilters);
