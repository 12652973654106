import PropTypes from 'prop-types';
import React, {useState, useRef, useEffect} from 'react';
import cn from 'classnames';
import styles from './KiMenu.theme.scss';
import KiIconButton from 'components/KiIconButton';

const KiMenu = ({
	children,
	isActive,
	onChange,
	className,
	popoverClassName,
	listClassName,
	buttonClassName,
	activeClassName,
	label,
	onClose,
	icon,
	position,
	title,
	iconButtonClass,
	isHidden,
	footer,
	buttonId,
}) => {
	const [internalIsActive, setInternalIsActive] = useState(false);
	const rootRef = useRef(null);

	const handleChange = (value) => {
		if (onClose && !value) {
			onClose();
		}

		if (onChange) {
			onChange(value);
		}

		if (typeof isActive === 'undefined') {
			setInternalIsActive(value);
		}
	};

	const handleClickOutside = (event) => {
		if (rootRef.current && !rootRef.current.contains(event.target)) {
			handleChange(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	});

	const handleClickItem = () => {
		handleChange(false);
	};

	if (isHidden) {
		return null;
	}

	const _isActive = typeof isActive !== 'undefined' ? isActive : internalIsActive;

	return (
		<div
			className={cn(styles.root, className, {
				[activeClassName]: _isActive,
			})}
			ref={rootRef}
		>
			{label ? (
				<button
					type="button"
					className={buttonClassName}
					onMouseUp={() => handleChange(!_isActive)}
					title={title}
				>
					{label}
				</button>
			) : null}
			{icon ? (
				<KiIconButton
					className={cn(styles.icon, 'menuIcon', iconButtonClass)}
					icon={icon}
					onClick={() => handleChange(!_isActive)}
					title={title}
					{...(buttonId && {id: buttonId})}
				/>
			) : null}
			<div
				className={cn(styles.popover, styles[position], popoverClassName, {
					[styles.hidden]: !_isActive,
				})}
			>
				<ul className={cn([styles.list, styles[position], listClassName])}>
					{React.Children.map(children, (child) => {
						if (!child) {
							return null;
						}

						return React.cloneElement(child, {
							onSelect: handleClickItem,
						});
					})}
				</ul>
				{footer}
			</div>
		</div>
	);
};

KiMenu.propTypes = {
	isActive: PropTypes.bool,
	onChange: PropTypes.func,
	className: PropTypes.string,
	popoverClassName: PropTypes.string,
	listClassName: PropTypes.string,
	label: PropTypes.element,
	buttonClassName: PropTypes.string,
	activeClassName: PropTypes.string,
	onClose: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	position: PropTypes.string,
	icon: PropTypes.string,
	title: PropTypes.string,
	iconButtonClass: PropTypes.string,
	isHidden: PropTypes.bool,
	buttonId: PropTypes.string,
	footer: PropTypes.element,
};

KiMenu.defaultProps = {
	className: '',
	children: [],
	position: 'topLeft',
	isHidden: false,
};

export default KiMenu;
