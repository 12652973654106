import React from 'react';
import PropTypes from 'prop-types';
import styles from './KiCardPayments.theme.scss';
import {defaultData} from './KiCardPaymentsUtils';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import Select from 'react-select';
import KiDatePicker from 'components/KiDatePicker';
//import {buildTitle} from 'ki-common/utils/explorerUtils';

function KiCardPaymentsFilters(props) {
	const {
		displayType,
		fundingVehicle,
		fundingVehicles,
		statementDate,
		setDisplayType,
		setFundingVehicle,
		setStatementDate,
	} = props;

	return (
		<section className={styles.cardFilters}>
			<div className={styles.double}>
				<span className="form-instruction">Display Type:</span>
				<Select
					classNamePrefix="aut-select"
					value={displayType}
					isClearable={false}
					isDisabled={true}
					options={[
						{
							label: 'Data',
							value: 'data',
							type: 'data',
						},
					]}
					onChange={val => {
						setDisplayType(val);
						//setSummaryColumn(null);
					}}
				/>
			</div>
			<div>
				<span className="form-instruction">Statement Date</span>
				<KiDatePicker
					className={styles.filterDatePicker}
					value={statementDate}
					onChange={val => setStatementDate(dateToShortDate(val))}
				/>
			</div>
			<div className={styles.double}>
				<span className="form-instruction">Funding Vehicle</span>
				<Select
					classNamePrefix="aut-select"
					isClearable={true}
					isSearchable={true}
					options={fundingVehicles}
					value={fundingVehicle}
					getOptionLabel={option => option.name}
					getOptionValue={option => option._id}
					onChange={option => setFundingVehicle(option)}
				/>
			</div>
		</section>
	);
}

KiCardPaymentsFilters.propTypes = {
	data: PropTypes.object.isRequired,
	card: PropTypes.object.isRequired,
	view: PropTypes.object.isRequired,
	fundingVehicles: PropTypes.array,
	displayType: PropTypes.object,
	fundingVehicle: PropTypes.object,
	statementDate: PropTypes.string,
	summaryColumn: PropTypes.object,
	columnType: PropTypes.object,
	setDisplayType: PropTypes.func,
	setFundingVehicle: PropTypes.func,
	setStatementDate: PropTypes.func,
	setSummaryColumn: PropTypes.func,
	setColumnType: PropTypes.func,
	scenarioList: PropTypes.array,
	scenarioId: PropTypes.string,
	setScenarioOption: PropTypes.func,
};

KiCardPaymentsFilters.defaultProps = {
	data: defaultData,
	view: {},
	card: {},
	statementDate: dateToShortDate(new Date()),
	scenarioList: [],
};

export default KiCardPaymentsFilters;
