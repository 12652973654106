// Globals
import React, {useEffect, useState, useContext} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import moment from 'moment';

// Project imports
import {format} from 'ki-common/utils/displayFormatter';
import {AbsTable} from '@moodysanalytics/cs-structured-ux-common';

// Website imports
import KiButton from 'components/KiButton';
import {usePrevious} from 'utils/customHooks';

// Website api
import {fetchScenarioAnalysisPost, fetchSummaryAnalysisPost} from 'api/fundingAnalysisApi';

// Local imports
import FundingAnalysisContext from '../../fundingAnalysisContext';
import {BookmarkContext} from '../../fundingAnalysisBookmark';
import styles from './fundingExploration.theme.scss';
import FundingScenarioActions from './FundingScenarioActions';
import QuickFilters from './QuickFilters';

const defaultTableData = {
	columns: [],
	rows: [],
};

function FundingExploration() {
	// Browser State
	const {datasetId, bookmarkId} = useParams();

	// Redux State
	const allFundingVehicles = useSelector(state => state.fundingVehicleList.data);

	// Context State
	const fundingAnalysisContext = useContext(FundingAnalysisContext);
	const bookmarkContext = useContext(BookmarkContext);
	const bookmark = bookmarkContext.bookmark;
	const parentBookmark = bookmarkContext.parent;
	const prevBookmark = usePrevious(bookmark);

	// Local State
	const [analysisData, setAnalysisData] = useState(defaultTableData);
	const [tableLoading, setTableLoading] = useState(true);

	//Offset snapshot date by one in breaches view url - BLD-21062
	const dateOffset = 24 * 60 * 60 * 1000 * 0.5;
	const snapshotDate = new Date(analysisData.snapshotDate);
	snapshotDate.setTime(snapshotDate.getTime() - dateOffset);
	const newSnapshotDate = moment(snapshotDate).format('YYYY-MM-DD');

	// OnLoad
	useEffect(() => {
		const scenarioId = new URLSearchParams(location.search).get('scenarioId');
		bookmarkContext.initializeBookmark(datasetId, bookmarkId, scenarioId);
	}, []);

	// Based on fetchAnalysisData and fetchAnalysisSummaryData
	const fetchAnalysisDataByBookmark = async () => {
		// No need to alter fundingVehicleIds here, we do that on the control now
		let tableData;
		try {
			if (['summary', 'eligibility'].includes(bookmark.settings.viewType)) {
				// Summary
				tableData = await fetchSummaryAnalysisPost(bookmark, bookmark.settings.transferDate);
			} else {
				// Scenario
				tableData = await fetchScenarioAnalysisPost(bookmark, bookmark.settings.scenarioId);
			}

			// Deal with incorrectly formatted data
			if (!tableData || !tableData.rows || (!tableData.columns && !tableData.columnInfo)) {
				tableData = defaultTableData;
			}
			return tableData;
		} catch (e) {
			tableData = defaultTableData;
			fundingAnalysisContext.addError(e.message);
			return tableData;
		}
	};

	const getAnalysisData = async () => {
		setTableLoading(true);
		const data = await fetchAnalysisDataByBookmark();
		setAnalysisData(data);
		setTableLoading(false);
	};

	useEffect(
		() => {
			if (bookmark && prevBookmark && bookmark.datasetId && prevBookmark.datasetId !== bookmark.datasetId) {
				getAnalysisData();
			}
		},
		[bookmark]
	);

	useEffect(
		() => {
			if (bookmark && bookmark.datasetId) {
				getAnalysisData();
			}
		},
		[bookmarkContext.appliedBookmark]
	);

	const getDrillDownData = async (type, cell = null) => {
		const cellFv = allFundingVehicles.find(fv => fv.name === cell.row.original.data[0]);
		bookmarkContext.loadDrillDown(type, cellFv._id);
	};

	const _getScenarioId = () => {
		if (bookmark.settings.scenarioId) {
			// Actual Scenario
			const scenario = bookmarkContext.scenario || {};
			// HYPO scenario -> scenarioId
			// PENDING/SUBMITTED scenario -> transfer date
			// CLOSED scenario -> End of Day
			switch (scenario.status) {
				case 'approved': // PENDING
				case 'submitted':
					return scenario.transferDate;
				case 'closed':
					return 'lastApproved'; // End of Day
				case 'hypo':
				default:
					return scenario._id;
			}
		} else {
			// Pending or Start/End
			return bookmark.settings.transferDate || null;
		}
	};

	const drillDownTableValue = async cell => {
		if (cell.column.columnName === 'kiFVName') {
			getDrillDownData('pool', cell);
		}
		if (cell.column.columnName === 'end_excess') {
			getDrillDownData('excess', cell);
		}
		if (cell.column.columnName === 'end_breaches') {
			getDrillDownData('breaches', cell);
		}
		if (cell.column.columnName === 'summary_excess') {
			getDrillDownData('excess', cell);
		}
		if (cell.column.columnName === 'summary_Breaches') {
			getDrillDownData('breaches', cell);
		}
		if (cell.column.columnName === 'br_ex_name' || cell.column.columnName === 'comp_test_name') {
			const exclusionLink = `dataExplorer/${datasetId}?fundingView=true&exclusionType=${
				cell.column.columnName
			}&fundingSnapshotDate=${newSnapshotDate}
				&dataColumnId=${cell.row.original.meta.dataColumnId}
				&groupBy=${cell.row.original.meta.groupByColumnId}
				&fvId=${bookmark.settings.fundingVehicleIds[0]}
				&scenarioType=${bookmark.settings.scenarioType}
				&scenarioId=${_getScenarioId()}
				&tableType=${bookmark.settings.tableType}
				&snapshotType=${bookmark.settings.isBlended ? 'blended' : 'standard'}`;
			const win = window.open(exclusionLink, '_blank');
			win.focus();
		}
	};

	//TEMP - remove when BE is fixed
	const getColumnName = () => {
		if (analysisData.columns) {
			return analysisData.columns;
		} else if (analysisData.columnInfo) {
			return analysisData.columnInfo;
		} else {
			return [];
		}
	};

	const getCellValueByMultiplier = (value, displayFormat) => {
		if (['9,999%', '9,999.99%', '9,999.99999%', '9,999.*%'].includes(displayFormat)) {
			return value * 100;
		}
		return value;
	};

	const getHeaderCell = (col, key) => {
		return (
			<div style={{whiteSpace: 'pre'}} title={col.displayName} key={key}>
				{col.displayName}
			</div>
		);
	};

	const getTableCell = (col, cell) => {
		if (
			['kiFVName', 'end_excess', 'end_breaches', 'summary_excess', 'summary_Breaches'].includes(col.columnName) &&
			bookmark.settings.viewType !== 'eligibility' &&
			!parentBookmark &&
			!(col.columnName === 'kiFVName' && bookmark.settings.tableType === 'summary')
		) {
			return (
				<div
					onClick={() => drillDownTableValue(cell)}
					style={{
						textDecoration: 'underline',
						fontSize: '12px',
						cursor: 'pointer',
					}}
				>
					{format(cell.value, col.displayFormat)}
				</div>
			);
		}
		if (['comp_test_name', 'br_ex_name'].includes(col.columnName)) {
			return (
				<div
					onClick={() => drillDownTableValue(cell)}
					style={{
						textDecoration: 'underline',
						fontSize: '12px',
						cursor: 'pointer',
					}}
				>
					{format(cell.value, col.displayFormat)}
				</div>
			);
		}
		return <div>{format(getCellValueByMultiplier(cell.value, col.displayFormat), col.displayFormat)}</div>;
	};

	const columnsToRender = getColumnName().map((col, idx) => {
		const key = `${col._id}-${idx}`;
		return {
			...col,
			key,
			id: key,
			Header: () => getHeaderCell(col, key),
			//Header: `${col.displayName}`,
			//Header: getHeaderCell(col, idx),
			isSorted:
				idx === 0 && !['breaches', 'scenario', 'excess'].includes(bookmark.settings.viewType) ? true : false,
			accessor: d => d.data[idx],
			Cell: cell => getTableCell(col, cell),
		};
	});

	return (
		<div className={styles.root}>
			<QuickFilters onApply={() => bookmarkContext.applyBookmark()} />
			<div>
				<AbsTable
					columns={columnsToRender}
					data={analysisData.rows}
					isLoading={tableLoading}
					isSortEnabled={true}
					isFilterEnabled={true}
					noDataMessage="No data found for the selected Statement Date / Date Context combination"
					showNoDataMessage={!tableLoading && analysisData.rows?.length === 0}
				/>
			</div>
			<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
				{parentBookmark && (
					<KiButton
						label="BACK"
						type="submit"
						iconButton={true}
						icon="arrow_back"
						primary
						onClick={bookmarkContext.restoreParent}
					/>
				)}
				{!parentBookmark && <div />}
				<FundingScenarioActions />
			</div>
		</div>
	);
}

export default FundingExploration;
