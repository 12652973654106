// Globals
import {generateObjectId} from 'ki-common/utils/constraintUtil';
import React, {useEffect, useState, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {DragDropContext} from 'react-beautiful-dnd';

// Project imports
import {KiConfirmModal, KiIconButton, KiList, KiListItem} from 'components';
import {getGroupByColumnsForDataset} from 'api/columnServiceApi';

// Local imports
import styles from './fundindModelDetail.theme.scss';
import FundingAnalysisContext from '../fundingAnalysisContext';
import FundingVehicleDragList from './FundingVehicleDragList';
import ConstraintsModal from './ConstraintsModal';
import {showSnackbar} from 'state/actions/Snackbar';

const ConstraintGroupListItem = props => {
	return (
		<div className={styles.constraintsListItem}>
			<div className={styles.constraintsListItemLabel} onMouseUp={props.onEdit}>
				{props.name}
			</div>
			<div className={styles.constraintsListItemActions}>
				<KiIconButton
					disabled={props.constraintGroup?.isReadOnly && !props.constraintGroup?.isFVDefined}
					icon="content_copy"
					onClick={props.onCopy}
				/>
				<KiIconButton icon="delete" disabled={props.constraintGroup?.isReadOnly} onClick={props.onDelete} />
			</div>
		</div>
	);
};

ConstraintGroupListItem.propTypes = {
	name: PropTypes.string,
	onDelete: PropTypes.func.isRequired,
	onEdit: PropTypes.func.isRequired,
	onCopy: PropTypes.func.isRequired,
	constraintGroup: PropTypes.object,
};

export const FundingModelDetailContents = () => {
	const fundingAnalysisContext = useContext(FundingAnalysisContext);
	const datasetId = useSelector(state => state.datasetList.selected.datasetId);
	const [isConstraintModalActive, setIsConstraintModalActive] = useState(false);
	const [deletionConfirmModalIsVisible, setDeletionConfirmModelIsVisible] = useState(false);
	const dispatch = useDispatch();
	// TODO move to ki-common
	const optimizationTypes = [
		{label: 'First Compliant', value: 'first'},
		{label: 'First Break', value: 'best'},
		{label: 'Minimize Excess', value: 'minimize_excess'},
	];
	const modelTypes = [
		{label: 'Balanced Funding', value: 'balanced_funding'},
		{label: 'Max Funding', value: 'max_funding'},
	];

	const [groupByName, setGroupByName] = useState('loading...');

	const handleClickConstraintGroupCreate = () => {
		fundingAnalysisContext.setStateItem('currentConstraintGroup', {
			constraintGroupName: 'New Constraint Group',
			constraints: [],
			fundingModelId: fundingAnalysisContext.fundingModelId,
			isFVDefined: false,
			isReadOnly: false,
		});
		setIsConstraintModalActive(true);
	};

	const handleClickConstraintGroupEdit = constraintGroup => {
		fundingAnalysisContext.setStateItem('currentConstraintGroup', constraintGroup);
		setIsConstraintModalActive(true);
	};

	const handleClickConstraintGroupDelete = constraintGroup => {
		fundingAnalysisContext.setStateItem('currentConstraintGroup', constraintGroup);
		setDeletionConfirmModelIsVisible(true);
	};

	const onConstraintGroupDelete = async constraintGroup => {
		await fundingAnalysisContext.deleteConstraintGroup(constraintGroup._id).catch(err => {
			dispatch(showSnackbar(`Failed to Delete Constraint Group "${constraintGroup?.constraintGroupName}"`));
			return Promise.reject(err);
		});

		setDeletionConfirmModelIsVisible(false);
	};

	const handleSaveConstraintGroup = async constraintGroup => {
		await fundingAnalysisContext.saveConstraintGroup(constraintGroup);
		setIsConstraintModalActive(false);
	};

	const handleClickConstraintGroupCopy = async constraintGroup => {
		// append " (\d)" to copies, starting from 1, and if there is already one, increase it for each until you have a unique name.
		let newName,
			copyCount = 0;
		do {
			newName = `${constraintGroup.constraintGroupName} (${++copyCount})`;
		} while (fundingAnalysisContext.constraintGroups.find(cg => cg.constraintGroupName === newName));
		const copiedConstraintGroup = {
			constraintGroupName: newName,
			constraints: constraintGroup.constraints.map(constraint => ({...constraint, _id: generateObjectId()})),
			fundingVehicleId: constraintGroup.fundingVehicleId,
			isFvDefined: false,
			isReadOnly: false,
		};
		fundingAnalysisContext.setStateItem('currentConstraintGroup', copiedConstraintGroup);
		setIsConstraintModalActive(true);
	};

	// On Mount
	useEffect(
		() => {
			async function getGroupByName() {
				const groupByCols = await getGroupByColumnsForDataset(datasetId);
				const match = groupByCols.find(col => col._id === fundingAnalysisContext.groupBy);
				setGroupByName(match ? match.detailedDisplayName : 'No Detail');
			}
			if (datasetId) {
				getGroupByName();
			} else {
				setGroupByName('loading...');
			}
		},
		[datasetId]
	);

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<h4>{fundingAnalysisContext?.name}</h4>
			</div>
			<div className={styles.details}>
				<section className={styles.sources}>
					<DragDropContext onDragEnd={() => ({})}>
						<div>
							<div className={styles.sectionHeader}>
								<p className={styles.detailSectionHeader}>SOURCES</p>
							</div>
							<div className={styles.sectionContent}>
								<FundingVehicleDragList
									itemList={fundingAnalysisContext.fvSources}
									droppableId={'1'}
									dragEnabled={false}
									isOrderEnabled
								/>
							</div>
						</div>
						<div>
							<div className={styles.sectionHeader}>
								<p className={styles.detailSectionHeader}>TARGETS</p>
							</div>
							<div className={styles.sectionContent}>
								<FundingVehicleDragList
									itemList={fundingAnalysisContext.fvTargets}
									droppableId={'2'}
									dragEnabled={false}
									isOrderEnabled
								/>
							</div>
						</div>
					</DragDropContext>
				</section>
				<section>
					<p className={styles.detailSectionHeader}>OPTIONS</p>
					<div className={styles.optionsTable}>
						<div className={styles.optionsColumn}>
							<p style={{fontWeight: 'bold'}}>Type:</p>
							<p style={{fontWeight: 'bold'}}>Group By:</p>
							<p style={{fontWeight: 'bold'}}>Optimization:</p>
							{/*<p style={{fontWeight: 'bold'}}>Fractional:</p>*/}
							<p style={{fontWeight: 'bold'}}>Include Submitted:</p>
							<p style={{fontWeight: 'bold'}}>Blended:</p>
							<p style={{fontWeight: 'bold'}}>Topoff:</p>
						</div>
						<div className={styles.optionsColumn}>
							<p>
								{modelTypes.find(t => t.value === fundingAnalysisContext.type)
									? modelTypes.find(t => t.value === fundingAnalysisContext.type).label
									: ' '}
							</p>
							<p>{groupByName ? groupByName : '\u00A0'}</p>
							<p>
								{optimizationTypes.find(t => t.value === fundingAnalysisContext.optimization)
									? optimizationTypes.find(t => t.value === fundingAnalysisContext.optimization).label
									: ' '}
							</p>
							{/*<p>{fundingAnalysisContext.isFractional ? 'True' : 'False'}</p>*/}
							<p>{fundingAnalysisContext.includePrevAssets ? 'True' : 'False'}</p>
							<p>{fundingAnalysisContext.isBlended ? 'True' : 'False'}</p>
							<p>{fundingAnalysisContext.isTopoff ? 'True' : 'False'}</p>
						</div>
					</div>
				</section>
				<section>
					<header className={styles.detailSectionHeader}>
						CONSTRAINT GROUPS
						<KiIconButton
							className={styles.addConstraintBtn}
							icon="add"
							onMouseUp={handleClickConstraintGroupCreate}
						/>
					</header>
					<div className={styles.sectionContent}>
						<KiList className={styles.constraintsList}>
							{fundingAnalysisContext.constraintGroups?.map?.((constraintGroup, index) => (
								<KiListItem key={index}>
									<ConstraintGroupListItem
										name={constraintGroup.constraintGroupName}
										constraintGroup={constraintGroup}
										onEdit={() => handleClickConstraintGroupEdit(constraintGroup)}
										onDelete={() => handleClickConstraintGroupDelete(constraintGroup)}
										onCopy={() => handleClickConstraintGroupCopy(constraintGroup)}
									/>
								</KiListItem>
							))}
						</KiList>
					</div>
				</section>
			</div>
			{!!isConstraintModalActive && (
				<ConstraintsModal
					isActive={isConstraintModalActive}
					setModalActive={setIsConstraintModalActive}
					fundingVehicles={[
						...fundingAnalysisContext.fvSources.map(fv => {
							fv.fvType = 'source';
							return fv;
						}),
						...fundingAnalysisContext.fvTargets.map(fv => {
							fv.fvType = 'target';
							return fv;
						}),
					].filter(fv => !fv.isUnencumbered && fv.fvName !== 'Unencumbered')} //filter out isUnencumbered
					curConstraintGroup={fundingAnalysisContext.currentConstraintGroup}
					allConstraintGroups={[]}
					onSaveFunc={handleSaveConstraintGroup}
					fundingModel={fundingAnalysisContext.model}
					isReadOnly={fundingAnalysisContext.currentConstraintGroup?.isReadOnly}
				/>
			)}
			<KiConfirmModal
				header={`Delete ${fundingAnalysisContext.currentConstraintGroup?.constraintGroupName ||
					'Constraint Group'}`}
				message="Are you certain you wish to delete this Constraint Group?"
				acceptFunc={() => onConstraintGroupDelete(fundingAnalysisContext.currentConstraintGroup)}
				rejectFunc={() => setDeletionConfirmModelIsVisible(false)}
				acceptLabel="Delete"
				rejectLabel="Cancel"
				active={deletionConfirmModalIsVisible}
			/>
		</div>
	);
};

export default FundingModelDetailContents;
