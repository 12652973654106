import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from '../../FlyoutManageViews.theme.scss';
import Select from 'react-select';
import {KiCreatable} from 'components/KiSelect';
import KiInput from 'components/KiInput';
import KiCheckbox from 'components/KiCheckbox';
import _get from 'lodash/get';
import _flatten from 'lodash/flatten';
import {explorerApi, datasetDatesApi} from 'api';
import {updateColumns, updateValue, addFormError, removeFormError, updateGroupBy, updateCohortColumn} from './actions';
import TimeSeriesColumnSelector from 'containers/dataExplorer/components/TimeSeriesColumnSelector';
import TimeSeriesPeriodSelector from 'containers/dataExplorer/components/TimeSeriesPeriodSelector';
import TimeSeriesDisplayPeriodSelector from 'containers/dataExplorer/components/TimeSeriesDisplayPeriodSelector';
import KiDatePicker from 'components/KiDatePicker';
//import KiDateSelectorWithContext from 'components/KiDateSelectorWithContext';
import {getDefaultColumnIds} from 'ki-common/utils/explorerUtils';
import {withRouter} from 'react-router-dom';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import KiSelect from 'components/KiSelect';

export const DATA_TRANSFER_ASSET_LEVEL_VIEW_ID_OPTIONS = [
	{value: 'encumbranceAssetLevel', isDisabled: true, label: 'Encumbrance Asset Level'},
	{value: 'encumbrancePoolSummary', isDisabled: true, label: 'Encumbrance Pool Summary'},
	{value: 'encumbranceStratification', label: 'Encumbrance Stratification'},
];

export class Settings extends Component {
	static propTypes = {
		view: PropTypes.object,
		dataset: PropTypes.object,
		updateValue: PropTypes.func.isRequired,
		isCopy: PropTypes.bool,
		user: PropTypes.object,
		addFormError: PropTypes.func,
		removeFormError: PropTypes.func,
		isAdmin: PropTypes.bool,
		userBookmarks: PropTypes.array,
		setFormHasChanges: PropTypes.func,
		updateGroupBy: PropTypes.func,
		updateCohortColumn: PropTypes.func,
	};

	static defaultProps = {
		view: {},
		filters: [],
	};

	state = {
		fundingVehicles: [],
		pools: [],
		scenarios: [],
		dateContextList: [],
	};

	componentDidMount() {
		const {view, isCopy, user, dataset} = this.props;
		if (isCopy || !view._id) {
			this.valueChanged('createdBy', user.userId);
		}
		// sort the view form setting columns so we can index correctly
		const unsortedAssetColumns = view.columns.filter(c => c.columnType === 'asset');
		updateColumns(unsortedAssetColumns, 'asset');
		datasetDatesApi.fetchPortfolioDates(dataset.datasetId).then(dateContextList => {
			this.setState({dateContextList: dateContextList}, () => {
				setTimeout(this.updateQuickFilters, 0);
			});
		});
	}

	valueChanged = (name, value) => {
		this.props.updateValue({name, value});
		this.props.setFormHasChanges();
	};

	valueBlur = (name, value) => {
		this.validateField(name, value);
	};

	validateField = (name, value) => {
		if (value === '') {
			this.props.addFormError(name, 'Required');
		} else {
			this.props.removeFormError(name);
		}
	};

	tableTypeChanged = type => {
		if (type === 'timeSeries') {
			this.props.updateValue({
				name: 'timeseriesRange',
				value: 'last_6_periods',
			});
			this.props.updateValue({
				name: 'timeseriesPeriod',
				value: 'monthly',
			});
			// TODO: there must be a better way??
			this.props.updateValue({
				name: 'timeseriesColumn',
				value: this.props.dataset.columns.filter(col => col.columnType === 'aggregate')[0],
			});
			this.props.updateGroupBy('');
		} else {
			this.props.updateValue({name: 'timeseriesRange', value: ''});
			this.props.updateValue({name: 'timeseriesPeriod', value: ''});
			this.props.updateValue({name: 'timeseriesColumn', value: ''});
		}
		if (type === 'asset') {
			const defaultColumnIds = getDefaultColumnIds(
				this.props.dataset.snapshots,
				this.props.dataset.columns,
				this.props.view.snapshotDate
			);
			// remove grouping at asset level tables
			this.props.updateGroupBy('');
			this.props.updateCohortColumn(
				this.props.dataset.columns.find(c => c._id === defaultColumnIds.balanceCohortColumnId)
			);
		}
		this.props.updateValue({name: 'tableType', value: type});
		this.props.setFormHasChanges();
	};

	handleSetTimeSeriesColumn = column => {
		const targetCol = this.props.dataset.columns.find(c => c._id === column) || {_id: column};
		this.props.updateValue({name: 'timeseriesColumn', value: targetCol});
		this.props.setFormHasChanges();
	};

	handleSetTimeSeriesRange = range => {
		this.props.updateValue({name: 'timeseriesRange', value: range});
		this.props.setFormHasChanges();
	};

	handleSetTimeSeriesPeriod = period => {
		this.props.updateValue({name: 'timeseriesPeriod', value: period});
		this.props.setFormHasChanges();
	};

	handleStatementDateChange = selected => {
		this.valueChanged('statementDate', dateToShortDate(selected));
		setTimeout(this.updateQuickFilters, 0);
	};

	handleDataTransferViewIdChanged = option => {
		this.valueChanged('dataTransferViewId', (option && option.value) || null);
	};

	scenarioChanged = value => {
		this.valueChanged('scenarioId', value);
		setTimeout(this.updateQuickFilters, 0);
	};

	fundingVehicleChanged = value => {
		this.valueChanged('fundingVehicleId', value);
		setTimeout(this.updateQuickFilters, 0);
	};

	poolChanged = value => {
		this.valueChanged('poolId', value);
		setTimeout(this.updateQuickFilters, 0);
	};

	updateQuickFilters = () => {
		const {view} = this.props;
		const datasetId = _get(this.props, 'dataset.datasetId', _get(this.props, 'match.params.datasetId', ''));
		return explorerApi
			.fetchQuickFilters(
				datasetId,
				view.scenarioId,
				view.fundingVehicleId,
				view.statementDate,
				view.scenarioType,
				view.dateContext,
				this.state.dateContextList,
				view.isFixedDate,
				_get(this.props, 'dataset.snapshots', [])
			)
			.then(res => {
				this.setState({
					fundingVehicles: res.fundingVehicleList,
					pools: res.poolList,
					scenarios: res.scenarioList,
				});
			});
	};

	handleCreatableSelect = tags => {
		this.valueChanged('tags', tags.map(v => v.value));
	};

	findActiveScenario = () => {
		const {scenarios} = this.state;
		const {
			view: {scenarioId},
		} = this.props;

		const activeById = _flatten(scenarios.map(scenario => (scenario.options ? scenario.options : scenario))).find(
			scenario => scenario.value === scenarioId
		);
		return activeById || scenarios.find(s => s.value === 'lastApproved');
	};

	render() {
		const {view, dataset} = this.props;
		const {fundingVehicles, pools, scenarios} = this.state;
		const viewTypeOptions = [
			{
				value: 'cohort',
				label: 'Summary',
			},
			{
				value: 'asset',
				label: 'Asset',
			},
			{
				value: 'timeSeries',
				label: 'Time Series',
			},
		];
		return (
			<div className={styles.settingsRoot}>
				<div className={styles.form}>
					<KiInput
						name="name"
						label="View Name"
						type="text"
						value={this.props.view.name}
						error={this.props.view.formErrors.name}
						onChange={val => this.valueChanged('name', val)}
						onBlur={e => this.valueBlur('name', e.target.value)}
					/>
					<KiCreatable
						classNamePrefix="aut-select"
						isMulti={true}
						options={[]}
						onChange={this.handleCreatableSelect}
						placeholder={'Add Tags'}
						value={view.tags.map(tag => ({value: tag, label: tag}))}
					/>
				</div>

				<div className="sidebar-form-section">
					<span className="form-instruction">Date:</span>
					<KiDatePicker
						onChange={this.handleStatementDateChange}
						value={view.statementDate}
						className={styles.explorerDatePicker}
					/>
				</div>
				{!this.props.view.isFixedDate && (
					<div className="sidebar-form-section">
						<span className="form-instruction">Date Context:</span>
						<Select
							classNamePrefix="aut-select"
							value={this.state.dateContextList.find(d => d._id === this.props.view.dateContext)}
							isClearable={false}
							options={this.state.dateContextList}
							onChange={val => this.valueChanged('dateContext', val)}
							getOptionLabel={option => option.name}
							getOptionValue={option => option._id}
						/>
					</div>
				)}
				<div className="sidebar-form-section">
					<KiCheckbox
						name="isDefault"
						checked={this.props.view.snapshotType === 'blended'}
						label="Blended"
						onChange={val => this.valueChanged('snapshotType', val ? 'blended' : 'standard')}
					/>
				</div>
				<div className="sidebar-form-section">
					<KiCheckbox
						name="isDefault"
						checked={this.props.view.isFixedDate}
						label="Static Date"
						onChange={val => this.valueChanged('isFixedDate', val)}
					/>
				</div>
				<div className="sidebar-form-section">
					<span className="form-instruction">View Type:</span>
					<Select
						classNamePrefix="aut-select"
						value={viewTypeOptions.find(opt => opt.value === this.props.view.tableType)}
						isClearable={false}
						options={viewTypeOptions}
						onChange={option => this.tableTypeChanged(option.value)}
					/>
				</div>

				{this.props.view.tableType === 'timeSeries' && [
					<div className="sidebar-form-section" key={1}>
						<TimeSeriesColumnSelector
							dataset={dataset}
							targetFunction={this.handleSetTimeSeriesColumn}
							view={view}
						/>
					</div>,
					<div className="sidebar-form-section" key={2}>
						<TimeSeriesDisplayPeriodSelector
							dataset={dataset}
							targetFunction={this.handleSetTimeSeriesRange}
							view={view}
						/>
					</div>,
					<div className="sidebar-form-section" key={3}>
						<TimeSeriesPeriodSelector
							dataset={dataset}
							targetFunction={this.handleSetTimeSeriesPeriod}
							view={view}
						/>
					</div>,
				]}

				<div className="sidebar-form-section">
					<span className="form-instruction">Scenario:</span>
					<Select
						classNamePrefix="aut-select"
						value={this.findActiveScenario()}
						isClearable={false}
						options={scenarios}
						onChange={option => this.scenarioChanged(option.value)}
					/>
				</div>

				<div className="sidebar-form-section">
					<span className="form-instruction">Funding Vehicle:</span>
					<Select
						classNamePrefix="aut-select"
						value={fundingVehicles.find(fv => fv.value === view.fundingVehicleId)}
						isClearable={false}
						options={fundingVehicles}
						onChange={option => this.fundingVehicleChanged(option.value)}
					/>
				</div>

				<div className="sidebar-form-section">
					<span className="form-instruction">Pool:</span>
					<Select
						classNamePrefix="aut-select"
						value={pools.find(p => p.value === view.poolId) || pools.find(p => p.value === '')}
						isClearable={false}
						options={pools}
						onChange={option => this.poolChanged(option.value)}
					/>
				</div>

				<div hidden={!view.isGlobal || !view.dataTransferViewId} className="sidebar-form-section">
					<span className="form-instruction">Data Transfer View Type:</span>
					<KiSelect
						options={DATA_TRANSFER_ASSET_LEVEL_VIEW_ID_OPTIONS}
						value={DATA_TRANSFER_ASSET_LEVEL_VIEW_ID_OPTIONS.filter(
							x => x.value === view.dataTransferViewId
						)}
						onChange={this.handleDataTransferViewIdChanged}
						placeholder="N/A"
						isDisabled={
							true
							// !view.isGlobal ||
							// !this.props.isAdmin ||
							// (view.dataTransferViewId && view.dataTransferViewId !== 'encumbranceStratification')
						}
						isClearable={view.dataTransferViewId === 'encumbranceStratification'}
					/>
				</div>

				<div className="sidebar-form-section">
					<KiCheckbox
						name="isDefault"
						checked={view.isDefault}
						label="Use as Default For Dataset"
						onChange={val => this.valueChanged('isDefault', val)}
					/>
					<KiCheckbox
						name="isFavorite"
						checked={view.isFavorite}
						label="Favorite"
						onChange={val => this.valueChanged('isFavorite', val)}
					/>
					{this.props.isAdmin && (
						<KiCheckbox
							name="isGlobal"
							checked={view.isGlobal}
							label="Global"
							onChange={val => this.valueChanged('isGlobal', val)}
						/>
					)}
				</div>
				<div className={`${styles.warningText} ${this.state.nameExistsWarning ? '' : styles.hidden}`}>
					{this.state.nameExistsWarning}
				</div>
				<div
					className={`${styles.warningText} ${
						!view.isDefault && view.isDefault && this.props.userBookmarks.find(v => v.isDefault == true)
							? ''
							: styles.hidden
					}`}
				>
					A default bookmark exists already and will be replaced.
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	view: state.viewForm,
});

const mapDispatchToProps = () => ({
	updateValue,
	addFormError,
	removeFormError,
	updateGroupBy,
	updateCohortColumn,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps()
)(withRouter(Settings));
