// Global Imports
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Select from 'react-select';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import {format} from 'ki-common/utils/displayFormatter';
import collateralMapList from 'ki-common/options/collateralMapList';
import {collateralMappingTypeOptions} from 'ki-common/options/debt';

// Project Imports
import KiFontIcon from 'components/KiFontIcon';
import KiTooltip from 'components/KiTooltip';
import {showSnackbar} from 'state/actions/Snackbar';
import {columnServiceApi} from 'api';
import * as api from 'api/waterfallMappingApi';

// Local Imports
import '../mappings.scss';

export class CollateralMappings extends Component {
	static propTypes = {
		user: PropTypes.object,
		dataset: PropTypes.object,
		fundingVehicles: PropTypes.array,
		showSnackbar: PropTypes.func,
	};

	state = {
		currentFundingVehicle: null,
		currentMappings: [],
		savedMappings: [],
		colMappingList: [],
		currentVariableType: {label: 'Collateral', value: 'collateral'},
	};

	componentDidMount() {
		return this.fetchMappings();
	}

	componentDidUpdate(prevProps) {
		if (this.props.dataset && this.props.dataset !== prevProps.dataset) {
			this.fetchMappings();
		}
	}

	fetchMappings() {
		const {dataset} = this.props;
		const colMappingList = _cloneDeep(collateralMapList);

		const colParams = {};
		// Any asset aggregate columns that are exposed to debt
		_set(colParams, 'sources.includeAggregateColumns', true);
		_set(colParams, 'options.onlyDebtExposedAggregate', true);

		// Only numerics
		//_set(colParams, 'filters.dataTypes', ['numeric']);

		return Promise.all([
			api.fetchMappingsByDataset(dataset.datasetId),
			columnServiceApi.getColumnsFromService(dataset.datasetId, colParams),
		]).then(results => {
			results[0].forEach(mapping => {
				const index = colMappingList.findIndex(m => m.name === mapping.name);
				colMappingList[index] = mapping;
			});

			const relevantMappings = colMappingList.filter(c => c.type == this.state.currentVariableType.value);
			this.setState({
				colMappingList: colMappingList,
				currentMappings: relevantMappings,
				savedMappings: _cloneDeep(relevantMappings),
				aggregateCols: results[1],
			});
		});
	}

	setFundingVehicle = val => {
		const {dataset} = this.props;
		const colMappingList = _cloneDeep(collateralMapList);
		this.setState({currentFundingVehicle: val});
		if (val._id === 'all') {
			return api.fetchMappingsByDataset(dataset.datasetId).then(mappings => {
				mappings.forEach(mapping => {
					const index = colMappingList.findIndex(m => m.name === mapping.name);
					colMappingList[index] = mapping;
				});
				const relevantMappings = colMappingList.filter(c => c.type == this.state.currentVariableType.value);
				this.setState({currentMappings: relevantMappings, savedMappings: _cloneDeep(colMappingList)});
			});
		} else {
			return api.fetchMappingsByDatasetAndFundingVehicle(dataset.datasetId, val._id).then(mappings => {
				mappings.forEach(mapping => {
					const index = colMappingList.findIndex(m => m.name === mapping.name);
					colMappingList[index] = mapping;
				});
				const relevantMappings = colMappingList.filter(c => c.type == this.state.currentVariableType.value);
				this.setState({currentMappings: relevantMappings, savedMappings: _cloneDeep(colMappingList)});
			});
		}
	};

	setDefault = (val, index) => {
		const updatedMappings = [...this.state.currentMappings];
		updatedMappings[index].defaultValue = val._id;
		updatedMappings[index].isDirty = true;
		this.setState({currentMappings: updatedMappings});
	};

	setOverride = (val, index) => {
		const updatedMappings = [...this.state.currentMappings];
		updatedMappings[index].overrideValue = (val && val._id) || null;
		updatedMappings[index].isDirty = true;
		//updatedMappings[index].fundingVehicleId = this.state.currentFundingVehicle._id;
		if (updatedMappings[index].fundingVehicleId !== this.state.currentFundingVehicle._id) {
			if (!updatedMappings[index].fundingVehicleId) {
				updatedMappings[index]._id = 'new';
			}
			updatedMappings[index].fundingVehicleId = this.state.currentFundingVehicle._id;
		}
		this.setState({currentMappings: updatedMappings});
	};

	handleSave = index => {
		const {user, dataset} = this.props;
		const mappingToSave = {...this.state.currentMappings[index]};
		delete mappingToSave.isDirty;
		mappingToSave.datasetId = dataset.datasetId;
		mappingToSave.type = this.state.currentVariableType.value;
		if (this.state.currentMappings[index]._id) {
			if (mappingToSave._id === 'new') {
				mappingToSave.createdDate = new Date();
				mappingToSave.createdBy = user.userId;
				delete mappingToSave._id;
			}
			mappingToSave.modifiedDate = new Date();
			mappingToSave.modifiedBy = user.userId;
			return api.addDatasetMapping(mappingToSave).then(res => {
				this.props.showSnackbar('Mapping saved successfully.');
				if (res.upserted && res.upserted.length > 0) {
					mappingToSave._id = res.upserted[0]._id;
				}
				const mappings = [...this.state.currentMappings];
				mappings[index] = mappingToSave;
				this.setState({currentMappings: mappings});
			});
		} else {
			mappingToSave.createdDate = new Date();
			mappingToSave.createdBy = user.userId;
			return api.addDatasetMapping(mappingToSave).then(res => {
				this.props.showSnackbar('Mapping saved successfully.');
				mappingToSave._id = res.upserted[0]._id;
				const mappings = [...this.state.currentMappings];
				mappings[index] = mappingToSave;
				this.setState({currentMappings: mappings});
			});
		}
	};

	handleUndo = index => {
		const {currentMappings, savedMappings} = this.state;
		const updatedMappings = [...currentMappings];
		updatedMappings[index] = {...savedMappings[index]};
		delete updatedMappings[index].isDirty;
		this.setState({currentMappings: updatedMappings});
	};

	getOverrideValue = mapping => {
		const {currentFundingVehicle, aggregateCols} = this.state;
		if (currentFundingVehicle && currentFundingVehicle._id === mapping.fundingVehicleId) {
			const match = aggregateCols.find(option => {
				return option._id === mapping.overrideValue;
			});
			return match;
		}
		return null;
	};

	render() {
		const {currentMappings, currentFundingVehicle, aggregateCols} = this.state;
		// {
		// 	_id: null,
		// 	datasetId: null,
		// 	fundingVehicleId: null,
		// 	createdDate: null,
		// 	modifiedDate: null,
		// 	name: 'mapping 1',
		// 	displayName: 'mapping 2',
		// 	createdBy: 'dasf',
		// 	modifiedBy: 'other user',
		// 	overrideValue: 'some override',
		// 	defaultValue: 'some default',
		// }

		let mappingsToShow = [...currentMappings];
		if (currentFundingVehicle && currentFundingVehicle._id !== 'all') {
			mappingsToShow = mappingsToShow.filter(
				mapping =>
					mapping.defaultValue || mapping.fundingVehicleId === currentFundingVehicle._id || !mapping._id
			);
		}

		return (
			<section>
				<div className={'select-wrapper mapping-fv-select'}>
					<span className={'theme-label'}>Funding Vehicle:</span>
					<Select
						placeholder="Select a Funding Vehicle..."
						value={currentFundingVehicle || {name: 'All', _id: 'all'}}
						options={[{name: 'All', _id: 'all'}, ...this.props.fundingVehicles]}
						onChange={val => this.setFundingVehicle(val)}
						getOptionLabel={option => option.name}
						getOptionValue={option => option._id}
					/>
					{window.FORECASTING_ENABLED === 'true' && (
						<div>
							<span className={'theme-label'}>Variables:</span>
							<Select
								placeholder="Select a Set of Variables..."
								value={this.state.currentVariableType}
								options={collateralMappingTypeOptions}
								onChange={val => {
									this.setState({
										currentVariableType: val,
									});
									this.fetchMappings();
								}}
								getOptionLabel={option => option.label}
								getOptionValue={option => option.value}
							/>
						</div>
					)}
				</div>
				<table className="dataset-mapping-list">
					<thead>
						<tr>
							<th>
								<p>{`${this.state.currentVariableType.value.toUpperCase()} VARIABLE`}</p>
							</th>
							<th>
								<p>DEFAULT</p>
							</th>
							{currentFundingVehicle &&
								currentFundingVehicle._id !== 'all' && (
									<th>
										<p>OVERRIDE</p>
									</th>
								)}
							<th>
								<p>MAPPED</p>
							</th>
							<th style={{width: '100%'}}>
								<p>LAST UPDATED</p>
							</th>
							<th>&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						{mappingsToShow.map((mapping, index) => {
							return (
								<tr key={index}>
									<td style={{display: 'flex', alignItems: 'center', textAlign: 'left'}}>
										{mapping.displayName}&nbsp;
										<KiTooltip className="collateral-map-tooltip" message={mapping.info}>
											<i alt="Calculation" className="material-icons">
												info
											</i>
										</KiTooltip>
									</td>
									<td>
										<Select
											classNamePrefix="aut-select"
											getOptionLabel={option => option.detailedDisplayName}
											getOptionValue={option => option._id}
											isClearable={false}
											isDisabled={currentFundingVehicle && currentFundingVehicle._id !== 'all'}
											options={aggregateCols.filter(
												c => c.dataType === (mapping.datatype || 'numeric')
											)}
											value={
												aggregateCols.find(option => {
													return option._id === mapping.defaultValue;
												}) || null
											}
											className="ki-form-select-horizontal"
											onChange={val => this.setDefault(val, index)}
										/>
									</td>
									{currentFundingVehicle &&
										currentFundingVehicle._id !== 'all' && (
											<td>
												<Select
													key={index}
													classNamePrefix="aut-select"
													getOptionLabel={option => option.detailedDisplayName}
													getOptionValue={option => option._id}
													isClearable={true}
													options={aggregateCols}
													value={this.getOverrideValue(mapping)}
													className="ki-form-select-horizontal"
													onChange={val => this.setOverride(val, index)}
												/>
											</td>
										)}
									<td>
										<p>
											{mapping.createdBy || ''} {format(mapping.createdDate, 'YYYY-MM-DD')}
										</p>
									</td>
									<td>
										<p>
											{mapping.modifiedBy || ''} {format(mapping.modifiedDate, 'YYYY-MM-DD')}
										</p>
									</td>
									<td style={{verticalAlign: 'middle'}}>
										<KiFontIcon
											value="undo"
											onClick={!mapping.isDirty ? () => {} : () => this.handleUndo(index)}
											className={`icon ki-icon-rollover ${
												!mapping.isDirty ? 'ki-icon-disabled' : ''
											}`}
										/>
										<KiFontIcon
											value="save"
											onClick={!mapping.isDirty ? () => {} : () => this.handleSave(index)}
											className={`icon ki-icon-rollover ${
												!mapping.isDirty ? 'ki-icon-disabled' : ''
											}`}
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</section>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user,
	dataset: state.datasetList.selected,
	fundingVehicles: state.fundingVehicleList.data.filter(fv => fv.datasetId === state.datasetList.selected.datasetId),
});

const mapDispatchToProps = dispatch => ({
	showSnackbar: msg => dispatch(showSnackbar(msg)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CollateralMappings);
