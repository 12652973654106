// Globals
import React, {useState, useEffect, useContext} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import {AbsTable} from '@moodysanalytics/cs-structured-ux-common';
import Select from 'react-select';
import socketManager from 'socket';

// Project Imports
import {longDateRe} from 'ki-common/utils/testUtils';

// Mapped Imports
import {showSnackbar} from 'state/actions/Snackbar';
import KiFontIcon from 'components/KiFontIcon';
import KiButton from 'components/KiButton';
import KiConfirmModal from 'components/KiConfirmModal';
import {deleteScenario, cancelOptimizeScenario} from 'api/fundingAnalysisApi';
import {fetchPortfolioDates} from 'api/datasetDatesApi';
import {getLatestSnapshotByDatasetId} from 'api/datasetsApi';

// Relative Imports
import FundingAnalysisContext from '../fundingAnalysisContext';
import styles from './fundingScenariosList.theme.scss';
import ScenarioInformationOverlay from './ScenarioInformationOverlay';
import ScenarioPriorityModal from './scenarioPriorityModal/ScenarioPriorityModal.js';

/**
 * [FundingScenariosList description]
 */
function FundingScenariosList() {
	// Browser State
	const {datasetId} = useParams();
	// Dataset selection handled in FundingPageLayout, it will update the URL path and change this param

	// Context State
	const fundingAnalysisContext = useContext(FundingAnalysisContext);
	const allScenarios = fundingAnalysisContext.allScenarios;

	// Router State
	const history = useHistory();
	const dispatch = useDispatch();

	// Redux State
	const isAdmin = useSelector(state => state.user.groups.includes('SystemAdmins'));

	// Local State
	const [scenarioToDelete, setScenarioToDelete] = useState({});
	const [isDeleteConfirmActive, setIsDeleteConfirmActive] = useState(false);
	const [informationOverlayActive, setInformationOverlayActive] = useState(false);
	const [informationOverlayId, setInformationOverlayId] = useState(null);
	const [tableLoading, setTableLoading] = useState(false);
	const stateFilterList = [
		{label: 'Active', value: 'active'},
		{label: 'Archived', value: 'archived'},
		{label: 'Recurring', value: 'recurring'},
	];
	const [stateFilter, setStateFilter] = useState(stateFilterList[0]);
	const [portfolioDates, setPortfolioDates] = useState([]);
	const [latestSnapshotDate, setLatestSnapshotDate] = useState(null);
	const [canceledScenarios, setCanceledScenarios] = useState([]);
	const [showPriorityModal, setShowPriorityModal] = useState(false);

	useEffect(
		() => {
			const fetchScenarioData = async () => {
				setTableLoading(true);
				if (!datasetId) return false;
				await fundingAnalysisContext.fetchScenarios(datasetId);
				await fetchPortfolioDates(datasetId).then(dates => setPortfolioDates(dates));
				setLatestSnapshotDate(await getLatestSnapshotByDatasetId(datasetId));
				setTableLoading(false);
			};

			// Run immediately
			fetchScenarioData();

			// Bind for websockets
			socketManager.on('scenario-status-update', fetchScenarioData);
			return function cleanup() {
				socketManager.off('scenario-status-update');
			};
		},
		[datasetId]
	);

	const deleteFundingScenario = async () => {
		setTableLoading(true);
		try {
			await deleteScenario(scenarioToDelete._id);
			dispatch(showSnackbar('Scenario successfully deleted.'));
		} catch (err) {
			fundingAnalysisContext.addError(`${scenarioToDelete.name}: ${err.message}`);
		}
		await fundingAnalysisContext.fetchScenarios(datasetId);
		setTableLoading(false);
		setIsDeleteConfirmActive(false);
	};
	const confirmDeleteFundingScenario = scenario => {
		setScenarioToDelete(scenario);
		setIsDeleteConfirmActive(true);
	};

	const setScenarioModifyIndicator = row => {
		if (row.original.modificationStatus === 'success') {
			return <div> {row.original.displayStatus} - Modified</div>;
		} else {
			return <div> {row.original.displayStatus} </div>;
		}
	};

	const renderDateCell = (row, accessor) => {
		let value = row[accessor];
		if (!longDateRe.test(value)) {
			const dateObj = portfolioDates.find(pd => pd.groupId === value);
			value = (dateObj && dateObj.name) || value;
		}
		return value;
	};

	const renderRowControls = params => {
		const rowScenario = params.row.original;

		const allowDeleteScenario =
			rowScenario.isRecurring ||
			['Not Run', 'Invalid', 'Hypo', 'Hypo Unsolved', 'Error'].includes(rowScenario.displayStatus);

		// A modification can only apply if the status is approved or submitted
		let allowModifyScenario = rowScenario.status === 'approved' || rowScenario.status === 'submitted';

		const pendingRecurringApprovals =
			rowScenario.isAutoApproved === true && rowScenario.displayStatus === 'Pending';

		if (window.FUNDING_APPROVALS_ENABLED === 'true') {
			// Disable if transfer date is > then latest snapshot date and approval is not complete
			if (rowScenario.transferDate > latestSnapshotDate && rowScenario.status === 'submitted')
				allowModifyScenario = false;

			// Disable if transfer date === latests snapshot date
			if (rowScenario.transferDate === latestSnapshotDate) allowModifyScenario = false;

			// Disable if the transfer date is processing at all
		} else if (rowScenario.displayStatus.toLowerCase() === 'processing') {
			allowModifyScenario = false;
		}

		// Determine if the scenario is a true drawdown, those cannot be modified
		const targets = rowScenario.fvSettings.filter(fv => fv.fvType === 'target');
		if (targets.length === 1 && targets.find(fv => fv.fvIsUnencumbered === true)) allowModifyScenario = false;

		return (
			<div
				style={{
					display: 'flex',
					flexGrow: 1,
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'flex-end',
				}}
			>
				{rowScenario.modificationStatus === 'fail' ? (
					<KiFontIcon
						className="list-icon error-icon"
						value="report_problem"
						title="Error"
						onClick={() => {
							setInformationOverlayId(rowScenario._id);
							setInformationOverlayActive(true);
						}}
					/>
				) : (
					<KiFontIcon
						className="list-icon-btn"
						value="info"
						title="Info"
						onClick={() => {
							setInformationOverlayId(rowScenario._id);
							setInformationOverlayActive(true);
						}}
					/>
				)}
				{pendingRecurringApprovals === false && (
					<KiFontIcon
						className="list-icon-btn"
						value="settings"
						title="Settings"
						onClick={() => {
							history.push(`/fundingAnalysis/${datasetId}/scenarios/${rowScenario._id}/detail`);
						}}
					/>
				)}
				{pendingRecurringApprovals === false &&
					allowModifyScenario && (
						<KiFontIcon
							className="list-icon-btn"
							value="edit"
							title="Edit"
							onClick={() => {
								history.push(`/fundingAnalysis/${datasetId}/scenarios/${rowScenario._id}/modify`);
							}}
						/>
					)}
				{isAdmin && (
					<KiFontIcon
						style={allowDeleteScenario ? {} : {color: '#ccc'}}
						className="list-icon-btn"
						value="delete"
						title="Delete"
						onClick={
							allowDeleteScenario
								? () => confirmDeleteFundingScenario(rowScenario)
								: () => {
										return false;
								  }
						}
					/>
				)}
				{rowScenario.displayStatus === 'Running' && (
					<KiButton
						mini={true}
						disabled={
							canceledScenarios.includes(rowScenario._id) ||
							'constraintGroupId' in rowScenario.modification
						}
						primary
						onClick={async () => {
							setCanceledScenarios(canceled => [...canceled, rowScenario._id]);
							await cancelOptimizeScenario(rowScenario._id);
							dispatch(showSnackbar('Scenario successfully canceled.'));
							fundingAnalysisContext.fetchScenarios(datasetId);
						}}
					>
						Cancel
					</KiButton>
				)}
				{rowScenario.displayStatus !== 'Running' && (
					<KiButton
						mini={true}
						primary
						disabled={rowScenario.isRecurring}
						onClick={async () => {
							const bm = await fundingAnalysisContext.fetchDefaultScenarioBookmark(
								datasetId,
								rowScenario._id
							);
							history.push(
								`/fundingAnalysis/${datasetId}/bookmark/${bm._id}?scenarioId=${rowScenario._id}`
							);
							fundingAnalysisContext.setStateItem('tabIndex', 0);
						}}
					>
						View
					</KiButton>
				)}
			</div>
		);
	};

	const filterTableData = () => {
		switch (stateFilter.value) {
			case 'archived':
				return allScenarios.filter(s => !s.isRecurring && s.status === 'closed');
			case 'active':
				return allScenarios.filter(s => !s.isRecurring && s.status !== 'closed');
			case 'recurring':
				return allScenarios.filter(s => s.isRecurring);
			default:
				return allScenarios;
		}
	};

	const tableData = filterTableData();

	return (
		<React.Fragment>
			<div className={styles.controlsRow}>
				{stateFilter.value === 'recurring' && (
					<div className={styles.controlWrapper}>
						<KiButton primary onClick={() => setShowPriorityModal(true)}>
							Recurring Scenario Order
						</KiButton>
					</div>
				)}
				<div className={styles.controlWrapper}>
					<p className="theme-label">State:</p>
					<Select
						classNamePrefix="aut-select"
						isClearable={false}
						options={stateFilterList}
						value={stateFilter}
						onChange={val => setStateFilter(val)}
					/>
				</div>
			</div>
			<AbsTable
				isLoading={tableLoading}
				data={tableData}
				showNoDataMessage={!tableLoading && tableData?.length === 0}
				isSortEnabled={true}
				isFilterEnabled={true}
				columns={[
					{Header: 'Scenario Name', accessor: 'name', id: 1},
					{Header: 'User', accessor: 'createdBy', id: 2},
					{
						Header: 'Funding Model',
						accessor: row => {
							if ([undefined, 'Not Found'].includes(row.fundingModelName)) {
								return `${row.lastSolvedModel?.name || 'Unknown'} (deleted)` || 'Missing';
							}
							return row.fundingModelName;
						},
						// eslint-disable-next-line react/display-name
						Cell: data => {
							const isFundingModelNameFound = [undefined, 'Not Found'].includes(
								data.row?.original?.fundingModelName
							);
							return (
								<div style={{display: 'contents', color: isFundingModelNameFound ? 'red' : 'inherit'}}>
									{data.value}
								</div>
							);
						},
						id: 3,
					},
					{
						Header: 'Constraints',
						accessor: row => {
							if ([undefined, 'Not Found'].includes(row.constraintGroupName)) {
								return `${row.lastSolvedConstraintGroup?.name || 'Unknown'} (deleted)` || 'Missing';
							}
							return row.constraintGroupName;
						},
						// eslint-disable-next-line react/display-name
						Cell: data => {
							const isConstraintGroupFound = [undefined, 'Not Found'].includes(
								data.row?.original?.constraintGroupName
							);
							return (
								<div style={{display: 'contents', color: isConstraintGroupFound ? 'red' : 'inherit'}}>
									{data.value}
								</div>
							);
						},
						id: 4,
					},
					{
						Header: 'Snapshot',
						accessor: row => renderDateCell(row, 'snapshotDate'),
						id: 5,
						width: 100,
					},
					{
						Header: 'Transfer Date',
						accessor: row => renderDateCell(row, 'transferDate'),
						id: 6,
						width: 100,
					},
					{
						Header: 'Last Start',
						accessor: 'updatedAt',
						id: 7,
						isSorted: true,
						Cell: params => moment(params.value).format('YYYY-MM-DD, h:mm:ss a'),
					},
					{
						Header: 'Run Time',
						accessor: 'runtime',
						width: 100,
					},
					{
						Header: 'Status',
						accessor: 'displayStatus',
						id: 8,
						Cell: ({row}) => setScenarioModifyIndicator(row),
					},
					{
						id: 'settings-button',
						disableFilters: true,
						width: isAdmin ? 242 : 206,
						Cell: row => renderRowControls(row),
					},
				]}
			/>
			<KiConfirmModal
				header="Delete Scenario"
				message={`Are you sure you want to delete Scenario: ${scenarioToDelete.name}?`}
				acceptFunc={() => deleteFundingScenario()}
				rejectFunc={() => setIsDeleteConfirmActive(false)}
				acceptLabel="Delete"
				rejectLabel="Cancel"
				active={isDeleteConfirmActive}
			/>
			<ScenarioInformationOverlay
				isActive={informationOverlayActive}
				scenarioId={informationOverlayId}
				setInformationOverlayActive={setInformationOverlayActive}
			/>
			{showPriorityModal && (
				<ScenarioPriorityModal isActive={showPriorityModal} closeFunction={() => setShowPriorityModal(false)} />
			)}
		</React.Fragment>
	);
}

FundingScenariosList.propTypes = {};

FundingScenariosList.defaultProps = {};

export default FundingScenariosList;
