import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Select from 'react-select';
import styles from '../../../components/FlyoutManageViews/FlyoutManageViews.theme.scss';
import classNames from 'classnames';
import {withRouter} from 'react-router-dom';
import debtExplorerApi from 'api/debtExplorerApi';
import {fetchAccounts} from 'containers/accounts/actions';
import {deleteBookmark, fetchBookmark, fetchBookmarks, saveBookmark} from '../actions';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _ from 'lodash';
import FlyoutConfirmPanel from '../../../components/FlyoutConfirmPanel';

const ViewLink = props => {
	const isGlobal = !props.bookmark.createdBy;
	const isDisabled = !props.isAdmin && isGlobal && !props.allowAll;
	return isDisabled ? (
		<div className="list-icon-btn">
			<i title={props.title} alt={props.alt} className={`material-icons disabled`}>
				{props.iconName}
			</i>
		</div>
	) : (
		<div className="list-icon-btn">
			<i title={props.title} alt={props.alt} className="material-icons" onClick={props.onClick}>
				{props.iconName}
			</i>
		</div>
	);
};

ViewLink.propTypes = {
	bookmark: PropTypes.object,
	isAdmin: PropTypes.bool,
	allowAll: PropTypes.bool,
	title: PropTypes.string,
	alt: PropTypes.string,
	iconName: PropTypes.string,
	onClick: PropTypes.func,
};

const ViewListItem = ({
	bookmark = {},
	history,
	copyBookmark,
	deleteBookmark,
	currentViewId,
	user,
	isAdmin,
	fetchBookmark,
	closeFlyoutPanel,
}) => {
	const isGlobal = bookmark.isGlobal;
	const isActive = bookmark._id === currentViewId;

	const clickAction = bookmark => {
		if (!bookmark.viewColumns) {
			return history.push(`/dataExplorer/${bookmark.datasetId}?bookmarkId=${bookmark._id}`);
		} else {
			if (isActive) {
				return fetchBookmark(bookmark._id);
			}
			return history.push(`/datasets/${bookmark.datasetId}/debt?bookmarkId=${bookmark._id}`);
		}
	};

	return (
		<div className={styles.row}>
			<div className={styles.rowInfo}>
				<div className={classNames({'list-icon-btn': true, 'active-text': isActive, 'no-rollover': true})}>
					<i title={isGlobal ? 'Global' : 'User'} className="material-icons">
						{isGlobal ? 'language' : 'person'}
					</i>
				</div>
				<div
					className={classNames({'list-icon-btn': true, 'active-text': isActive, 'link-text': true})}
					onClick={() => {
						clickAction(bookmark);
						closeFlyoutPanel();
					}}
				>
					{bookmark.name}
				</div>
				{bookmark.viewColumns && (
					<div className={classNames({'list-icon-btn': true, 'active-text': isActive, 'no-rollover': true})}>
						<i title="Default" className="material-icons">
							credit_card
						</i>
					</div>
				)}
				{bookmark.isDefault && (
					<div className={classNames({'list-icon-btn': true, 'active-text': isActive, 'no-rollover': true})}>
						<i title="Default" className="material-icons">
							home
						</i>
					</div>
				)}
				{bookmark.isFavorite && (
					<div className={classNames({'list-icon-btn': true, 'active-text': isActive, 'no-rollover': true})}>
						<i title="Favorite" className="material-icons">
							star
						</i>
					</div>
				)}
			</div>
			<div className={styles.rowActions}>
				<ViewLink
					isAdmin={isAdmin}
					allowAll={true}
					title="Copy"
					alt="Copy View"
					iconName="content_copy"
					onClick={() => copyBookmark(bookmark)}
					user={user}
					bookmark={bookmark}
				/>
				<ViewLink
					isAdmin={isAdmin}
					allowAll={false}
					title="Delete"
					alt="Delete View"
					iconName="delete"
					onClick={() => deleteBookmark(bookmark)}
					user={user}
					bookmark={bookmark}
				/>
			</div>
		</div>
	);
};

ViewListItem.propTypes = {
	bookmark: PropTypes.object,
	deleteBookmark: PropTypes.func,
	currentViewId: PropTypes.string,
	user: PropTypes.object,
	copyBookmark: PropTypes.func,
	isAdmin: PropTypes.bool,
	history: PropTypes.object,
	closeFlyoutPanel: PropTypes.func,
	fetchBookmark: PropTypes.func,
};

class ManageViews extends Component {
	static propTypes = {
		user: PropTypes.object,
		fetchAccounts: PropTypes.func,
		fetchBookmarks: PropTypes.func,
		match: PropTypes.object,
		deleteBookmark: PropTypes.func,
		bookmarks: PropTypes.array,
		userList: PropTypes.array,
		history: PropTypes.object,
		location: PropTypes.object,
		currentView: PropTypes.object,
		closeFlyoutPanel: PropTypes.func,
		fetchBookmark: PropTypes.func,
		saveBookmark: PropTypes.func,
	};

	static defaultProps = {
		userList: [],
	};

	isAdmin = this.props.user.groups.includes('SystemAdmins');

	constructor(props) {
		super(props);
		props.fetchAccounts();
		if (props.match.params.datasetId) {
			props.fetchBookmarks(props.match.params.datasetId);
		}
	}

	state = {
		searchTerm: '',
		selectedUserId: this.isAdmin ? 'global' : '',
		showConfirmDelete: false,
		selectedBookmark: null,
	};

	handleDeleteBookmarkClick = bookmark => {
		this.setState({
			showConfirmDelete: true,
			selectedBookmark: bookmark,
		});
	};

	handleDeleteBookmark = async () => {
		try {
			await this.props.deleteBookmark(this.state.selectedBookmark._id);
			this.setState({
				showConfirmDelete: false,
				selectedBookmark: null,
				bookmarks: this.props.bookmarks.filter(x => x._id !== this.state.selectedBookmark._id),
				noDeleteReasons: null,
			});
		} catch (e) {
			this.setState({
				showConfirmDelete: false,
				noDeleteReasons: e,
			});
		}
	};

	cancelNoDeleteReasons = () => {
		this.setState(state => ({
			selectedBookmark: null,
			bookmarks: this.props.bookmarks.filter(x => x._id !== state.selectedBookmark._id),
			noDeleteReasons: null,
		}));
	};

	handleCopyBookmark = bookmarkId => {
		if (this.state.copyInProgress) return;
		this.setState({copyInProgress: true});
		return debtExplorerApi
			.fetchBookmark(bookmarkId)
			.then(bookmark => {
				let copyName,
					i = 0;
				do {
					// checking for existing copies with the same name and adding one until it's unique
					copyName = `${bookmark.name} ${++i}`;
				} while (this.props.bookmarks.find(bookmark => bookmark.name === copyName));
				return this.props.saveBookmark({
					..._omit(bookmark, '_id'),
					name: copyName,
					isDefault: false,
				});
			})
			.finally(() => this.setState({copyInProgress: false}));
	};

	render() {
		const searchTerm = this.state.searchTerm.toLowerCase();
		const filteredBookmarks = this.props.bookmarks
			.filter(bookmark => {
				if (this.state.selectedUserId) {
					if (this.state.selectedUserId === 'global') {
						return bookmark.isGlobal;
					}
					return bookmark.createdBy === this.state.selectedUserId;
				}
				return true;
			})
			.filter(bookmark => this.isAdmin || bookmark.isGlobal || bookmark.createdBy === this.props.user.userId)
			.filter(
				bookmark =>
					!searchTerm ||
					(bookmark.name.toLowerCase().includes(searchTerm) ||
						bookmark.tags.find(t =>
							t
								.toString()
								.toLowerCase()
								.includes(searchTerm)
						))
			);

		const userListOptions = [
			{value: '', label: 'All Users'},
			{value: 'global', label: 'Global Only'},
			..._.sortBy(this.props.userList, u => `${u.lastName}${u.firstName}`.toLowerCase()).map(u => ({
				value: u.userId,
				label: `${u.firstName} ${u.lastName}`,
			})),
		];
		if (this.state.showConfirmDelete) {
			return (
				<FlyoutConfirmPanel
					header={'Delete'}
					message={`Are you sure you want to delete the following view? ${_get(
						this.state.selectedBookmark,
						'name',
						''
					)}`}
					acceptFunc={() => this.handleDeleteBookmark(this.state.selectedBookmark._id)}
					rejectFunc={() => this.setState({showConfirmDelete: false, selectedBookmark: null})}
					acceptLabel={'DELETE'}
					rejectLabel={'CANCEL'}
				/>
			);
		}
		if (this.state.noDeleteReasons) {
			return (
				<FlyoutConfirmPanel
					header={'Delete'}
					message={
						<span>
							Debt View <span className="bold">&quot;{this.state.selectedBookmark.name}&quot;</span>{' '}
							cannot be deleted because it is associated with the following:
						</span>
					}
					rejectFunc={this.cancelNoDeleteReasons}
					rejectLabel={'CLOSE'}
					hideAccept={true}
					reasonList={this.state.noDeleteReasons?.data?.dependencies}
				/>
			);
		}
		return (
			<div className="context-sidebar-panel-flex">
				<header className="flyout-panel-title">MANAGE VIEWS</header>
				<div>
					<section className="flyout-panel-search">
						{this.isAdmin && (
							<div className="flyout-panel-user-search">
								<p>Filter</p>
								<Select
									classNamePrefix="aut-select"
									className={styles.picker}
									closeOnSelect={true}
									hint="Select a User"
									isClearable={false}
									value={userListOptions.find(x => x.value === this.state.selectedUserId)}
									options={userListOptions}
									onChange={option => this.setState({selectedUserId: option.value})}
								/>
							</div>
						)}
						<p>Search a View</p>
						<input
							placeholder="Name or Tag"
							value={this.state.searchTerm}
							onChange={e => this.setState({searchTerm: e.target.value})}
						/>
					</section>
					{!filteredBookmarks.length ? (
						<p>No Views Found</p>
					) : (
						<section className={styles.listContainer}>
							{filteredBookmarks.map(bookmark => {
								return (
									<ViewListItem
										fetchBookmark={this.props.fetchBookmark}
										key={bookmark._id}
										bookmark={bookmark}
										history={this.props.history}
										location={this.props.location}
										copyBookmark={bookmark => this.handleCopyBookmark(bookmark._id)}
										closeFlyoutPanel={this.props.closeFlyoutPanel}
										deleteBookmark={this.handleDeleteBookmarkClick}
										currentViewId={this.props.currentView._id}
										user={this.props.user}
										isAdmin={this.isAdmin}
									/>
								);
							})}
						</section>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		userList: state.accounts.data,
		dataset: state.datasetList.selected,
		user: state.user,
		currentView: state.debtExploration.view,
		bookmarks: state.debtExploration.bookmarks,
	};
};

export default connect(
	mapStateToProps,
	{fetchAccounts, fetchBookmarks, fetchBookmark, deleteBookmark, saveBookmark}
)(withRouter(ManageViews));
