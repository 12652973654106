const crypto = require('crypto');
const moment = require('./moment-recur'); //exports moment with recur and extra functions
const fvDateRecurrenceOptions = require('../options').fvDateRecurrenceOptions;
const _ = require('lodash');
const {dateToShortDate, shortDateToDate} = require('./dateHelpers');

const IS_BROWSER = typeof window !== 'undefined';
const MEMOIZE_WITH_CURRENT_DATE = process.env.MEMOIZE_WITH_CURRENT_DATE === 'true';
const MEMOIZATION_ENABLED =
	!IS_BROWSER && (MEMOIZE_WITH_CURRENT_DATE || process.env.DISABLE_DATE_MEMOIZATION !== 'true');

/**
 * Generate a hash of arguments for memoization
 * @param args
 * @returns {string}
 */
const generateMemoizationHash = (...args) =>
	(MEMOIZE_WITH_CURRENT_DATE ? new Date().toISOString().slice(0, 10) : '') +
	crypto
		.createHash('sha1')
		.update(JSON.stringify(args))
		.digest('hex');

/**
 * Get a recur object from inputs
 * @param dateType
 * @param fvStartDate
 * @param fvEndDate
 * @param fromDate
 * @param startDate
 * @param endDate
 * @param range
 * @param recurrenceType
 * @param endAfter
 * @param calendar
 * @param recurrenceStep
 * @param businessDay
 * @returns {*}
 * @private
 */
// eslint-disable-next-line complexity
const _getRecurrence = ({
	dateType = 'recurring', // static | recurring | range
	fvStartDate,
	fvEndDate,
	fromDate,
	startDate,
	endDate,
	range = 'all',
	recurrenceType = 'none', // none|daily|weekly|monthly|monthly_end|yearly
	endAfter = 0,
	calendar,
	recurrenceStep = 1,
	businessDay = 'next',
} = {}) => {
	if (startDate && !moment(startDate, 'YYYY-MM-DD', true).isValid()) {
		throw new Error('invalid start date provided to date config');
	}
	if (endDate && !moment(endDate, 'YYYY-MM-DD', true).isValid()) {
		throw new Error('invalid start date provided to date config');
	}
	if (fvStartDate && !moment(fvStartDate, 'YYYY-MM-DD', true).isValid()) {
		throw new Error('invalid fvStartDate provided to date config');
	}
	if (fvEndDate && !moment(fvEndDate, 'YYYY-MM-DD', true).isValid()) {
		throw new Error('invalid fvEndDate date provided to date config');
	}
	if (fromDate && !moment(fromDate, 'YYYY-MM-DD', true).isValid()) {
		throw new Error('invalid fromDate provided to date config');
	}
	if (!!recurrenceType && !fvDateRecurrenceOptions.find(({value}) => value === recurrenceType)) {
		throw new Error(`invalid recurrenceType "${recurrenceType}"`);
	}
	if (range === 'endAfter' && (parseInt(endAfter) || 0) < 1) {
		throw new Error('endAfter value is not positive');
	}

	const includeFVStart = fvStartDate && startDate && moment(fvStartDate).isBefore(moment(startDate));
	const includeFVEnd = fvEndDate && endDate && range !== 'endAfter' && moment(fvEndDate).isAfter(moment(endDate));

	const recurSetup = {
		...(dateType === 'static' && {static: true}),
		...(includeFVStart && {first: fvStartDate}),
		...(startDate && {start: startDate}),
		...(endDate && {end: endDate}),
		...(includeFVEnd && {last: fvEndDate}),
		...(calendar &&
			!_.isString(calendar) && {
				workdays: calendar.workdays,
				holidays: calendar.holidays,
				onHoliday: businessDay || 'next',
			}),
	};

	const recurrence = moment.recur(recurSetup);
	Object.assign(recurrence, {
		dateType,
		recurrenceType,
		recurrenceStep: _.parseInt(recurrenceStep) || 1,
	});
	// eslint-disable-next-line no-use-before-define
	_setRecurrenceRules(recurrence);

	if (range === 'endAfter' && parseInt(endAfter) >= 1) {
		recurrence.endAfter(parseInt(endAfter));
		if (fvEndDate && recurrence.endDate() && recurrence.endDate().isBefore(fvEndDate)) {
			recurrence.lastDate(fvEndDate);
		}
	}
	recurrence.fromDate(fromDate || null);
	return recurrence;
};

const getRecurrence = MEMOIZATION_ENABLED ? _.memoize(_getRecurrence, generateMemoizationHash) : _getRecurrence;

/**
 * Helper to set recurrence type rules
 * @param {moment} recurrence - takes in a recurrence expected to have no rules set yet.
 * @returns {moment} recurrence - returns recurrence with rules attached
 * @private
 */
const _setRecurrenceRules = recurrence => {
	const {recurrenceType, recurrenceStep} = recurrence;
	const start = recurrence.startDate();
	if (recurrence.isStatic()) {
		return recurrence;
	}
	switch (recurrenceType) {
		case 'daily': {
			return recurrence.every(recurrenceStep, 'day');
		}
		case 'weekly': {
			return recurrence.every(recurrenceStep, 'week');
		}
		case 'monthly': {
			recurrence.daysOfMonth(start.date());
			if (recurrenceStep % 12 === 0) {
				// is a period of years (will skip leap years if date is feb 29th
				return recurrence.every(recurrenceStep / 12).years();
			} else if (12 % recurrenceStep === 0 && recurrenceStep !== 1) {
				// same months every year
				const monthsOfYear = _.times(12 / recurrenceStep, idx => start.month() + recurrenceStep * idx);
				return recurrence.monthsOfYear(monthsOfYear);
			}
			// try same date every month
			return recurrence.every(recurrenceStep, 'month');
		}
		case 'monthly_end': {
			Object.assign(recurrence, {noRangeAdjust: true});
			recurrence.daysOfMonth(31);
			const startDateIsLastDayOfMonth = recurrence.startDate().isSame(
				recurrence
					.startDate()
					.clone()
					.endOf('month')
					.startOf('day')
			);
			if (recurrence.dateType !== 'range' && !startDateIsLastDayOfMonth) {
				//change start date to end of month bc except for ranges, the first occurrence should match the start date.
				recurrence.startDate(
					recurrence
						.startDate()
						.clone()
						.endOf('month')
						.startOf('day')
						.format('YYYY-MM-DD')
				);
			}
			if (12 % recurrenceStep === 0 && recurrenceStep !== 1) {
				// same months every year
				const monthsOfYear = _.times(12 / recurrenceStep, idx => start.month() + recurrenceStep * idx);
				return recurrence.monthsOfYear(monthsOfYear);
			}
			return recurrence;
		}
		case 'quarterly': {
			// every(3, 'months') will skip month end days for short months
			// calculate them manually to get Feb 29th for recurring on the 31st, etc
			return recurrence
				.daysOfMonth(start.date())
				.monthsOfYear([
					recurrence.startDate().month(),
					recurrence.startDate().month() + 3,
					recurrence.startDate().month() + 6,
					recurrence.startDate().month() + 9,
				]);
		}
		case 'semi_annually': {
			return recurrence.daysOfMonth(start.date()).monthsOfYear([start.month(), start.month() + 6]);
		}
		case 'annually': {
			return recurrence.daysOfMonth(start.date()).monthsOfYear(start.month());
		}
		default: {
			return recurrence;
		}
	}
};

/**
 * Get all results for recurrenceSetting
 * @function
 * @param {{}} recurrenceSettingArg - recurrence entry
 * @param {number} [resultOffsetDays] - number of days to offset results by
 * @param {boolean} [accrual] - return an object containing a results array and an accrual count
 * @param {number} [extraResults=0] - ignore system date limit and return x number of results
 * @returns {{accrualDays: number, results: String[]}|String[]|[]}
 */
// eslint-disable-next-line complexity
const _getDates = (recurrenceSettingArg, resultOffsetDays, accrual, extraResults = 0) => {
	if (!recurrenceSettingArg) {
		return [];
	}
	const recurrenceSetting = Object.assign({}, recurrenceSettingArg.parent || recurrenceSettingArg);
	const offsetDays = parseInt(recurrenceSettingArg.parent ? recurrenceSettingArg.offset : resultOffsetDays);
	const recurrence = _.cloneDeep(getRecurrence(recurrenceSetting));
	let allDates = [];
	if (!recurrence.endDate()) {
		let endDate = moment().add(1, 'year');
		if (recurrence.startDate().isAfter(endDate)) {
			endDate = recurrence
				.startDate()
				.clone()
				.add(1, 'second');
		}
		recurrence.endDate(endDate.format('YYYY-MM-DD'));
	}

	try {
		//TODO: should we be slicing based on range adjust here?
		if (recurrence.dateType === 'range' && !recurrence.fromDate()) {
			allDates = [recurrence.startDate().clone()];
		}
		allDates.push(...recurrence.all().slice(recurrence.dateType === 'range' && !recurrence.noRangeAdjust ? 1 : 0));
	} catch (err) {
		allDates = [];
	}
	if (extraResults > 0 || parseInt(recurrenceSetting.offset) > 0) {
		const previousFromDate = (recurrence.fromDate() && recurrence.fromDate.clone()) || null;
		recurrence.fromDate(allDates.slice(-1).pop() || recurrence.endDate() || moment().dateOnly());
		allDates.push(...recurrence.next(Math.max(parseInt(recurrenceSetting.offset) || 0, extraResults || 0)));
		if (previousFromDate) {
			recurrence.fromDate(previousFromDate);
		}
	}

	// adjust each result to adhere to holiday calendar
	allDates = allDates.reduce((acc, date) => {
		Object.assign(date, _.pick(recurrence, ['holidays', 'workdays', 'onHoliday']));
		date.snapToBusinessDay(offsetDays);
		if (!acc.find(x => x.toString() === date.toString())) {
			return [...acc, date];
		}
		return acc;
	}, []);

	if (recurrence.dateType === 'range' && !recurrence.noRangeAdjust) {
		allDates = allDates.map(date => date.clone().subtract(1, 'seconds'));
	}
	if (accrual) {
		return {
			results: allDates.map(date => date.format('YYYY-MM-DD')),
			accrualDays: allDates.map((x, idx) =>
				x.diff(!idx ? recurrence.fromDate() || x : allDates[idx && idx - 1], 'days')
			),
		};
	}
	// TODO: Consider having this export the moment objects?
	//  It would be larger in JS (maybe remove calendar?), but convert to strings when converted to json.
	//  Could be useful to have moment methods + isBusinessDay, isWorkday, isHoliday accessible.
	return allDates.map(date => date.format('YYYY-MM-DD'));
};

/**
 * @function
 */
const getDates = MEMOIZATION_ENABLED ? _.memoize(_getDates, generateMemoizationHash) : _getDates;

/**
 * @param snapshotDate
 * @param distributionDates
 * @returns {null|*}
 * @private
 */
const _getDistributionDate = (snapshotDate, distributionDates = []) => {
	// console.log(`_getDistributionDate snapshotDate:${snapshotDate}`);
	const mSnapshotDate = moment(snapshotDate, 'YYYY-MM-DD', true);

	if (!mSnapshotDate.isValid()) {
		throw new Error('Invalid snapshot date');
	}

	const disDate = distributionDates
		.map(s => moment(s))
		.find(distributionDate => {
			return distributionDate.isAfter(mSnapshotDate);
		});

	if (
		!disDate ||
		moment(disDate)
			.subtract(1, 'month')
			.isAfter(mSnapshotDate)
	) {
		return null;
	}
	return disDate.format('YYYY-MM-DD');
};

const getDistributionDate = MEMOIZATION_ENABLED
	? _.memoize(_getDistributionDate, generateMemoizationHash)
	: _getDistributionDate;

/**
 * @function _getDeterminationDate
 * @alias getDeterminationDate
 * @param snapshotDate
 * @param determinationDates
 * @param distributionDate
 * @returns {null|*}
 * @private
 */
const _getDeterminationDate = (snapshotDate, determinationDates = [], distributionDate, dateConfig) => {
	// console.log(`_getDeterminationDate snapshotDate:${snapshotDate} distributionDate:${distributionDate}`);
	// console.log('_getDeterminationDate', dateConfig);
	const mSnapshotDate = moment(snapshotDate, 'YYYY-MM-DD', true);
	const mDistributionDate = moment(distributionDate, 'YYYY-MM-DD', true);
	if (!mSnapshotDate.isValid() || !mDistributionDate.isValid()) {
		return null;
	}

	// if determination date or distribution date is non monthly
	// only test for isBefore NOT isSameOrBefore(mSnapshotDate)

	const detDates = determinationDates.filter(s => {
		const mDeterminationDate = moment(s, 'YYYY-MM-DD', true);
		if (['monthly', 'monthly_end'].includes(dateConfig.recurrenceType)) {
			return (
				mDeterminationDate.isSameOrBefore(mSnapshotDate) &&
				mDeterminationDate.isSameOrBefore(moment(mDistributionDate).subtract(1, 'month'))
			);
		}
		return mDeterminationDate.isSameOrBefore(mSnapshotDate);
	});

	if (detDates.length) {
		return moment(detDates[detDates.length - 1]).format('YYYY-MM-DD');
	}
	return null;
};

const getDeterminationDate = MEMOIZATION_ENABLED
	? _.memoize(_getDeterminationDate, generateMemoizationHash)
	: _getDeterminationDate;

/**
 * @param determinationDate
 * @param cutoffDate
 * @param snapshotDate
 * @returns {*}
 */
const getPoolAsOfDate = (determinationDate, cutoffDate, snapshotDate) => {
	if (!cutoffDate) {
		return snapshotDate;
	}
	const mDeterminationDate = moment(determinationDate, 'YYYY-MM-DD', true);
	if (!mDeterminationDate.isValid()) {
		return snapshotDate;
	}

	const poolCutoff = moment(cutoffDate, 'YYYY-MM-DD', true);
	if (poolCutoff.isValid() && poolCutoff.isAfter(mDeterminationDate)) {
		// cutoff is after determination date so return cutoff
		return poolCutoff.format('YYYY-MM-DD');
	}
	// else return determination date
	return moment(determinationDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
};

/**
 *
 * @param workdays
 * @returns {{}|number[]|[string, unknown]}
 * @private
 */
const _convertWorkdaysObjToDaysArray = (workdays = {}) => {
	if (!workdays || _.isEmpty(workdays)) return [0, 1, 2, 3, 4, 5, 6];
	if (Array.isArray(workdays)) return workdays;
	const MAP = {
		sunday: 0,
		monday: 1,
		tuesday: 2,
		wednesday: 3,
		thursday: 4,
		friday: 5,
		saturday: 6,
	};
	return Object.entries(workdays).reduce((acc, [day, active]) => {
		if (active) {
			return [...acc, MAP[day]];
		}
		return acc;
	}, []);
};

// TODO: remove the nasty offsetDaysOverride workaround
// eslint-disable-next-line complexity,no-unused-vars
const _getSingleDate = (recurrenceSetting, snapshotDate, recursionLevel = 1, offsetDaysOverride = 0) => {
	let recurrence;
	let offset;
	let childOffset = 0;
	if (recurrenceSetting.parent) {
		recurrence = _.cloneDeep(getRecurrence(recurrenceSetting.parent));
		offset = parseInt(recurrenceSetting.parent.offset) || 0;
		childOffset = parseInt(recurrenceSetting.offset) || 0;
	} else {
		recurrence = _.cloneDeep(getRecurrence(recurrenceSetting));
		offset = parseInt(recurrenceSetting.offset) || 0;
	}

	// Calculate the boundByDate actual value given the snapshotDate
	if (recurrenceSetting.boundByDate) {
		// eslint-disable-next-line no-use-before-define
		const boundByResult = _getSingleDate(recurrenceSetting.boundByDate, snapshotDate);
		if (boundByResult) {
			const initialResult = _getSingleDate(
				{
					...recurrenceSetting,
					boundByDate: null,
				},
				snapshotDate
			);
			// Check if the unadjusted result would already match boundBy rules. If so, skip any adjustments.
			if (initialResult) {
				if (recurrenceSetting.boundByOperator === '>' && initialResult > boundByResult) {
					return initialResult;
				} else if (recurrenceSetting.boundByOperator === '<' && initialResult < boundByResult) {
					return initialResult;
				}
			}
			return _getSingleDate(
				{
					...recurrenceSetting,
					offset: offset + Number(recurrenceSetting.boundByOperator === '>'), // +1 if results must be after another date (Number(false)) === 0)
					boundByDate: null,
				},
				boundByResult
			);
		}
	}

	if (recurrence.dateType === 'range' && !recurrence.noRangeAdjust) {
		snapshotDate = moment(snapshotDate, 'YYYY-MM-DD')
			.add(1, 'day')
			.format('YYYY-MM-DD');
	}

	let result;
	if (recurrence.dateType === 'static' || [undefined, null, 'none'].includes(recurrence.recurrenceType)) {
		result = recurrence.startDate().clone();
	} else if (offset > 0) {
		if (offset === 1 && recurrence.matches(snapshotDate, false)) {
			result = moment(snapshotDate, 'YYYY-MM-DD').dateOnly();
		} else {
			result = recurrence
				.fromDate(snapshotDate)
				.next(offset)
				.pop();
		}
	} else {
		result = recurrence
			.fromDate(snapshotDate)
			.previous(1 + (Math.abs(offset) || 0))
			.pop();
	}

	if (!result) {
		// no result, quit early
		return null;
	}

	if (recurrence.dateType === 'range' && !recurrence.noRangeAdjust) {
		result.subtract(1, 'day');
	}
	//TODO: remove this workaround when possible, it's only for pool activity start dates
	// once we have real ranges we'll likely be able to remove
	if (![undefined, null, 0].includes(offsetDaysOverride)) {
		result.add(parseInt(offsetDaysOverride || 0), 'days');
	}

	// set calendar props on moment object to be accessible by moment fns
	Object.assign(result, _.pick(recurrence, ['holidays', 'workdays', 'onHoliday']));
	result.snapToBusinessDay(childOffset);

	// If the result is higher than the bound by value, recursively call back
	// with an offset one prior than it was before
	if ((recurrenceSetting.parent ? recurrenceSetting.parent.dateType : recurrenceSetting.dateType) === 'static') {
		return result.format('YYYY-MM-DD');
	}
	if (result.isSame(recurrence.firstDate()) || result.isSame(recurrence.lastDate())) {
		return result.format('YYYY-MM-DD');
	}
	if (result.isBefore(recurrence.startDate())) {
		//result is before start date
		return null;
	} else if (recurrence.endDate() && result.isAfter(recurrence.endDate())) {
		// result is after endDate
		return null;
	}
	return result.format('YYYY-MM-DD');
};

const getSingleDate = MEMOIZATION_ENABLED ? _.memoize(_getSingleDate, generateMemoizationHash) : _getSingleDate;

const getLatestSnapshotDate = (snapshotList, statementDate, defaultDate) => {
	const list = snapshotList
		.slice()
		.map(d => shortDateToDate(d.snapshotDate))
		.filter(d => d <= shortDateToDate(statementDate))
		.sort((a, b) => (a < b ? 1 : -1));
	const selectedSnapshot = dateToShortDate(list[0]);
	return selectedSnapshot || defaultDate; // return the match or the default
};

const hydrateDatesOntoPool = (pool = {}, dates = []) => {
	//TODO needs a refactor at some point
	if (pool.dates && pool.dates.length) {
		// use hydrated dates if given, otherwise use dates array.
		return _.cloneDeep(pool);
	}
	const poolDates = Object.values(_.groupBy(dates || [], x => x.type || x.groupId || x.name))
		.reduce((acc, groupDates = []) => {
			let match;
			if (pool.status === 'unencumbered') {
				match = (groupDates || []).find(date => {
					if (Array.isArray(date.fundingVehicles)) {
						return date.fundingVehicles.find(x => (x || '').toString() === 'unencumbered');
					} else if (date.fundingVehicleId) {
						return (date.fundingVehicleId || '').toString() === 'unencumbered';
					}
					return false;
				});
			} else {
				match = (groupDates || []).find(date => {
					if (Array.isArray(date.fundingVehicles)) {
						return date.fundingVehicles.find(x => (x || '').toString() === (pool.fundingVehicleId || '').toString());
					} else if (date.fundingVehicleId) {
						return (date.fundingVehicleId || '').toString() === (pool.fundingVehicleId || '').toString();
					}
					return false;
				});
			}
			if (!match) {
				match = (groupDates || []).find(date => {
					if (Array.isArray(date.fundingVehicles)) {
						return date.fundingVehicles.find(x => (x || '').toString() === 'default');
					} else if (date.fundingVehicleId) {
						return (date.fundingVehicleId || '').toString() === 'default';
					}
					return false;
				});
			}
			if (match) {
				acc.push(match);
			}
			return acc;
		}, [])
		.map((date, idx, originalArray) => {
			if (date.parentId && !date.parent) {
				const parent = originalArray.find(
					x => x.groupId === date.parentId && x.fundingVehicleId === date.fundingVehicleId
				);
				if (parent) {
					return Object.assign({}, date, {parent});
				}
			}
			if (date.boundByDateId && !date.boundByDate) {
				const boundByDate = originalArray.find(
					x => x.groupId === date.boundByDateId && x.fundingVehicleId === date.fundingVehicleId
				);
				if (boundByDate) {
					return Object.assign({}, date, {boundByDate});
				}
			}
			return date;
		});
	return Object.assign(_.cloneDeep(pool), {dates: poolDates});
};

// hydrated pools, dataset dates
const _calculateDatesForPools = (pools = [], dates = [], snapshotDate, useBlended = false) => {
	return pools.reduce((acc, poolOrig) => {
		const pool = hydrateDatesOntoPool(poolOrig, dates);

		const distributionDate = getDistributionDate(
			snapshotDate,
			getDates(pool.dates.find(d => d.type === 'distribution'))
		);
		const determinationDateConfig = dates.find(d => d.type === 'determination');
		const determinationDate = getDeterminationDate(
			snapshotDate,
			getDates(pool.dates.find(d => d.type === 'determination')),
			distributionDate,
			determinationDateConfig
		);
		const asOfDate =
			pool.status !== 'encumbered' || !useBlended
				? snapshotDate
				: getPoolAsOfDate(determinationDate, pool.cutoffDate, snapshotDate);
		/* eslint-disable no-prototype-builtins */
		const result = Object.assign(
			{},
			_.omit(pool, ['dates', 'fundingVehicle']),
			(pool.dates || []).reduce((acc, date) => {
				if (date.type && !pool.hasOwnProperty(`${date.type}Date`) && !acc.hasOwnProperty(`${date.type}Date`)) {
					acc[`${date.type}Date`] = getSingleDate(date, snapshotDate);
				}
				if (date.type && !pool.hasOwnProperty(`${date.type}`) && !acc.hasOwnProperty(`${date.type}`)) {
					acc[date.type] = getSingleDate(date, snapshotDate);
				}
				if (
					date.type &&
					!pool.hasOwnProperty(`ki${date.type.charAt(0).toUpperCase()}${date.type.substr(1)}Date`) &&
					!acc.hasOwnProperty(`ki${date.type.charAt(0).toUpperCase()}${date.type.substr(1)}Date`)
				) {
					acc[`ki${date.type.charAt(0).toUpperCase()}${date.type.substr(1)}Date`] = getSingleDate(date, snapshotDate);
				}
				if (date.groupId && !acc.hasOwnProperty(date.groupId)) {
					acc[date.groupId] = getSingleDate(date, snapshotDate);
				}
				return acc;
			}, {}),
			{
				distribution: distributionDate || null,
				distributionDate: distributionDate || null,
				kiDistributionDate: distributionDate || null,
				determination: determinationDate || null,
				determinationDate: determinationDate || null,
				kiDeterminationDate: determinationDate || null,
				asOfDate,
			}
		);
		return [...acc, result];
	}, []);
};

const calculateDatesForPools = MEMOIZATION_ENABLED
	? _.memoize(_calculateDatesForPools, generateMemoizationHash)
	: _calculateDatesForPools;

const clearCaches = () => {
	// eslint-disable-next-line no-console
	console.log('Ki Date Utility: Clearing result cache for previous day.');
	getDates.cache.clear();
	getRecurrence.cache.clear();
	getSingleDate.cache.clear();
	calculateDatesForPools.cache.clear();
};

if (MEMOIZATION_ENABLED && !MEMOIZE_WITH_CURRENT_DATE) {
	/*
	 bc some methods use the current day for endDate to give dates up until today or 1 year from today
	 we reset the memoized method's cache as soon as the day ends, and then every 24 hours after that.
	 */
	const timeUntilFirstReset = moment()
		.add(1, 'day')
		.startOf('day')
		.diff();
	setTimeout(() => {
		clearCaches();
		setInterval(clearCaches, 1000 * 60 * 60 * 24).unref();
	}, timeUntilFirstReset).unref();
}

module.exports = {
	getRecurrence,
	getDates,
	getPoolAsOfDate,
	getDeterminationDate,
	getDistributionDate,
	getSingleDate,
	calculateDatesForPools,
	_convertWorkdaysObjToDaysArray,
	hydrateDatesOntoPool,
	getLatestSnapshotDate,
	moment,
};
