import PropTypes from 'prop-types';
import React, {Component} from 'react';
import noop from 'lodash/noop';
import './ErrorSummary.scss';
import KiButton from 'components/KiButton';
import _groupBy from 'lodash/groupBy';

export class ErrorSummaryItem extends Component {
	//eslint-disable-line react/prefer-stateless-function
	static propTypes = {
		text: PropTypes.string,
		count: PropTypes.number,
		level: PropTypes.oneOf(['error', 'warning']),
		onClick: PropTypes.func,
	};

	render() {
		const {level, count, onClick = noop, text} = this.props;

		return (
			<div className={`error level-${level}`} onClick={level === 'warning' ? onClick : noop}>
				{`${count} ${text}`}
			</div>
		);
	}
}

export class ErrorSummary extends Component {
	static propTypes = {
		errors: PropTypes.array,
		setFilter: PropTypes.func,
		clearFilter: PropTypes.func,
	};

	getErrorMessage = (type, count = 1) => {
		const isMultiple = count > 1;
		switch (type) {
			case 'newColumnFound':
				return `new column${isMultiple ? 's' : ''} found`;

			case 'columnNotFound':
				return `column${isMultiple ? 's' : ''} missing`;

			case 'dataTypeMismatchNumeric':
				return `numeric data type mismatch${count > 1 ? 'es' : ''}`;

			case 'dataTypeMismatchShortDate':
				return `shortDate data type mismatch${isMultiple ? 'es' : ''}`;

			case 'dataTypeMismatchLongDate':
				return `longDate data type mismatch${isMultiple ? 'es' : ''}`;

			case 'dataTypeMismatchCharacter':
				return `string data type mismatch${isMultiple ? 'es' : ''}`;

			case 'AssetIDNotFound':
				return 'required column "AssetID" not mapped';

			case 'BalanceNotFound':
				return 'required column "Balance" not mapped';

			default:
				return null;
		}
	};

	getErrorHeader = () => {
		const typeCounts = _groupBy(this.props.errors, error => error.level);
		const errorsCount = (typeCounts['error'] && typeCounts['error'].length) || 0;
		const warningCount = (typeCounts['warning'] && typeCounts['warning'].length) || 0;
		if (errorsCount === 0 && warningCount === 0) {
			return <header className="no-errors-found">0 errors found for the schema</header>;
		}
		return (
			<header>
				<span>
					{errorsCount} Errors {warningCount} Warnings
				</span>
				<KiButton onMouseUp={() => this.props.clearFilter()}>Show All Columns</KiButton>
			</header>
		);
	};

	render() {
		const errors = _groupBy(this.props.errors, error => error.code);
		return (
			<div className="schema-error-panel">
				{this.getErrorHeader()}
				{!!this.props.errors.length && (
					<div className="schema-error-summary">
						{Object.keys(errors).map(errorType => (
							<ErrorSummaryItem
								key={errorType}
								text={this.getErrorMessage(errorType, errors[errorType].length) || 'Unknown Error'}
								count={errors[errorType].length}
								level={errors[errorType][0].level}
								onClick={() => this.props.setFilter(errors[errorType].map(e => e.columnId))}
							/>
						))}
					</div>
				)}
			</div>
		);
	}
}

export default ErrorSummary;
