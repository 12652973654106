// Globals
import React, {useEffect, useContext} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useParams} from 'react-router';

// Project imports
import {fetchModel, copyModel, getConstraintGroups} from 'api/fundingAnalysisApi';
import FundingAnalysisContext from '../fundingAnalysisContext';
import KiButton from 'components/KiButton';
// Local imports
import styles from './fundindModelDetail.theme.scss';
import FundingModelDetailContents from './FundingModelDetailContents';
/**
 * [FundingAnalysis description]
 */
function FundingModelDetail() {
	const fundingAnalysisContext = useContext(FundingAnalysisContext);
	const dataset = useSelector(state => state.datasetList.selected);
	const isAdmin = useSelector(state => state.user.groups.includes('SystemAdmins'));
	const history = useHistory();
	const {fundingModelId} = useParams();
	// On Mount
	useEffect(() => {
		const fetchModelData = async id => {
			// fundingAnalysisContext.setStateItem('isLoading', true);
			const [modelData, constraintData] = await Promise.all([fetchModel(id), getConstraintGroups(id)]);
			modelData.constraintGroups = constraintData;
			fundingAnalysisContext.setModel(modelData);
			// fundingAnalysisContext.setStateItem('isLoading', false);
		};
		if (fundingModelId) {
			fetchModelData(fundingModelId);
		}
	}, []);

	const onCopyModel = async () => {
		const modelCopy = fundingAnalysisContext.getModel();
		// delete modelCopy.constraintGroups;
		modelCopy.datasetId = dataset.datasetId;
		try {
			const copiedModel = await copyModel(modelCopy);
			if (copiedModel.status === 500) {
				throw new Error();
			}
			copiedModel.constraintGroups = modelCopy.constraintGroups;
			fundingAnalysisContext.setModel(copiedModel);
			fundingAnalysisContext.setStateItem('isCopy', true);
			history.push(`/fundingAnalysis/${dataset.datasetId}/models/create`);
		} catch (err) {
			fundingAnalysisContext.addError(`${fundingAnalysisContext.getModel().name}: ${err.message}`);
		}
	};

	return (
		<div className={styles.root}>
			<FundingModelDetailContents
				dragEnabled={false}
				constraintGroups={fundingAnalysisContext.constraintGroups}
			/>
			<div className={styles.buttons}>
				{isAdmin && (
					<KiButton label="Copy" type="submit" iconButton={false} primary onClick={() => onCopyModel()} />
				)}
				<KiButton
					label="Close"
					type="submit"
					iconButton={false}
					primary
					onClick={() => history.push(`/fundingAnalysis/${dataset.datasetId}/models`)}
				/>
			</div>
		</div>
	);
}

export default FundingModelDetail;
