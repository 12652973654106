import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import _ from 'lodash';
import {AbsTable} from '@moodysanalytics/cs-structured-ux-common';

import {KiInputCell} from './KiInputCell';
import {KiSelect, KiFontIcon, KiTooltip} from 'components';

import styles from './fundingScenarioForm.theme.scss';

export const validateDiscountRate = value => {
	if (value < 0) {
		return 'Min value is 0';
	} else if (value > 100) {
		return 'Max value is 100';
	} else if (value && (isNaN(value) || isNaN(parseFloat(value)))) {
		return 'Value is not a number';
	}
	return false;
};

export const ModificationForm = props => {
	const {scenario, fundingModel, objectiveOptions, setScenario, tableLoading, inputErrors, setInputErrors} = props;
	const location = useLocation();
	const isModificationForm = location.pathname.includes('modify');
	const [activeInput, setActiveInput] = useState({id: undefined, cursorPosition: 0});

	// Redux State
	const updateIsUsedByModIndicator = (field, value, fvId) => {
		const fvSettings = scenario.fvSettings;
		const rowIndex = fvSettings.findIndex(fv => fv.fvId === fvId);
		fvSettings[rowIndex][field] = value;
		setScenario({fvSettings});
	};

	const setFVSetting = (fvId, field, value) => {
		const newFvSettings = _.cloneDeep(scenario.fvSettings);
		const fvIndex = newFvSettings.findIndex(fv => fv.fvId === fvId);
		newFvSettings[fvIndex][field] = value;
		setScenario({fvSettings: newFvSettings});
	};

	const columns = [
		{
			Header: '',
			id: 1,
			isVisible: isModificationForm,
			width: 40,
			Cell: ({row}) =>
				isModificationForm &&
				row.original.fvIsUnencumbered === false &&
				(row.original.fvType === 'source' && (
					<input
						type="checkbox"
						className={'pool-checkbox'}
						name="fvCheck"
						checked={row.original.isUsableByMod}
						disabled={row.original.isDrawdownInFull === true}
						onChange={e => updateIsUsedByModIndicator('isUsableByMod', e.target.checked, row.original.fvId)}
					/>
				)),
		},
		{
			Header: 'Funding Vehicle',
			accessor: 'fvName',
			id: 2,
		},
		{
			Header: 'Type',
			accessor: 'fvType',
			id: 3,
		},
		{
			Header: 'Objective',
			accessor: 'objective',
			filter: (rows, columnIds, filterValue) =>
				rows.filter(row => {
					const option = objectiveOptions.find(col => col._id === row.original.objective);
					return !!option?.detailedDisplayName?.toLowerCase?.()?.includes?.(filterValue?.toLowerCase?.());
				}),
			id: 4,
			// disableFilters: true,
			tdClassName: styles.withDropdown,
			/* eslint-disable */
			Cell: ({row}) => (
				<KiSelect
					isDisabled={isModificationForm}
					getOptionLabel={option => option.detailedDisplayName}
					getOptionValue={option => option._id}
					options={objectiveOptions}
					value={objectiveOptions.find(col => col._id === scenario.fvSettings[row.index].objective)}
					onChange={option => setFVSetting(row.original.fvId, 'objective', option._id)}
					styles={{
						container: base => ({
							...base,
							minHeight: '26px',
							height: '26px',
						}),
						control: base => ({
							...base,
							minHeight: '26px',
							height: '26px',
							borderRadius: '0px',
						}),
						valueContainer: base => ({
							...base,
							padding: '0px 8px',
						}),
						singleValue: base => ({...base, height: '14px'}),
						indicatorsContainer: base => ({
							...base,
							padding: 0,
						}),
						dropdownIndicator: base => ({
							...base,
							paddingTop: 0,
							paddingBottom: 0,
						}),
						clearIndicator: base => ({
							...base,
							paddingTop: 0,
							paddingBottom: 0,
						}),
					}}
				/>
			),
			/* eslint-enable */
		},
		{
			/* eslint-disable */
			Header: ({row, index}) => (
				<div key={index} className={styles.fundingAmount}>
					<div>Funding/Draw Amount</div>
					<KiTooltip
						maxWidth={320}
						className={styles.selectWrapper}
						message={'Blank means maximum possible amount'}
					>
						<KiFontIcon className={styles.iconStyling} value="info" />
					</KiTooltip>
				</div>
			),
			/* eslint-enable */
			accessor: 'fundingAmount',
			id: 5,
			minWidth: 160,
			/* eslint-disable */
			Cell: ({row}) => {
				const inputId = 'fundingAmount' + row.index;
				return (
					<KiInputCell
						id={inputId}
						activeInput={activeInput}
						setActiveInput={setActiveInput}
						disabled={
							row.original.fvIsUnencumbered ||
							row.original.isDrawdownInFull === true ||
							(isModificationForm && row.original.isUsableByMod === false) ||
							(isModificationForm && row.original.fvType === 'target') ||
							(!isModificationForm &&
								fundingModel?.type === 'balanced_funding' &&
								row.original.fvType === 'source')
						}
						value={scenario.fvSettings.find(fv => fv.fvId === row.original.fvId)?.fundingAmount ?? ''}
						onChange={val => setFVSetting(row.original.fvId, 'fundingAmount', val)}
					/>
				);
			},
			/* eslint-enable */
		},
		{
			Header: 'Discount Rate %',
			accessor: 'discountRate',
			id: 6,
			/* eslint-disable */
			Cell: ({row}) => {
				const inputId = 'discountRate' + row.index;
				return (
					<KiInputCell
						value={scenario.fvSettings.find(fv => fv.fvId === row.original.fvId)?.discountRate ?? ''}
						onChange={value => {
							const error = validateDiscountRate(value);
							if (error) {
								setInputErrors(errors => ({
									...errors,
									[inputId]: error,
								}));
							} else {
								setInputErrors(errors => {
									const {[inputId]: value, ...newErrors} = errors;
									return newErrors;
								});
							}
							setFVSetting(row.original.fvId, 'discountRate', value);
						}}
						id={inputId}
						activeInput={activeInput}
						setActiveInput={setActiveInput}
						disabled={
							isModificationForm ||
							(!isModificationForm && row.original.fvIsUnencumbered) ||
							(!isModificationForm && row.original.fvType === 'source')
						}
						error={inputErrors[inputId]}
					/>
				);
			},
			/* eslint-enable */
		},
		{
			Header: 'Drawdown in Full',
			accessor: 'isDrawdownInFull',
			id: 7,
			disableFilters: true,
			Cell: ({row, index}) => {
				/* eslint-disable react/prop-types */
				if (row.original.fvType === 'source') {
					return (
						<div key={index}>
							<input
								checked={!!row.original.isDrawdownInFull}
								disabled={
									row.original.fvIsUnencumbered ||
									fundingModel?.type === 'balanced_funding' ||
									(isModificationForm && row.original.isUsableByMod === false)
								}
								onChange={e => setFVSetting(row.original.fvId, 'isDrawdownInFull', e.target.checked)}
								type="checkbox"
								className={'drawdown-checkbox'}
								name="scales"
							/>
						</div>
					);
				} else {
					return null;
				}
				/* eslint-enable react/prop-types */
			},
		},
	];
	return (
		<AbsTable
			isSortEnabled={true}
			isFilterEnabled={true}
			isLoading={tableLoading}
			showNoDataMessage={!tableLoading && scenario.fvSettings?.length === 0}
			data={scenario.fvSettings}
			columns={columns}
		/>
	);
};
ModificationForm.propTypes = {
	scenario: PropTypes.object.isRequired,
	setScenario: PropTypes.func.isRequired,
	tableLoading: PropTypes.bool,
	inputErrors: PropTypes.any,
	setInputErrors: PropTypes.func.isRequired,
	fundingModel: PropTypes.object.isRequired,
	objectiveOptions: PropTypes.any.isRequired,
};

ModificationForm.defaultProps = {
	tableLoading: false,
	fundingModel: {},
};
